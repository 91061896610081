import * as React from "react";
import Svg, {
  Pattern,
  Path,
  Defs,
  ClipPath,
  Use,
  G,
  Text,
  Circle,
} from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */
import { forwardRef } from "react";
import MapBooth from "../components/MapBooth";
import MapRoom from "../components/MapRoom";
const SvgVenueLevel3Map = (props, ref) => (
  <Svg
    id="venue_level3_map_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0}
    y={0}
    viewBox="0 0 1721.8 950.8"
    style={{
      enableBackground: "new 0 0 1721.8 950.8",
    }}
    xmlSpace="preserve"
    ref={ref}
    {...props}
  >
    <Pattern
      y={950.8}
      width={72}
      height={72}
      patternUnits="userSpaceOnUse"
      id="venue_level3_map_svg__Unnamed_Pattern"
      viewBox="3 -74.1 72 72"
      style={{
        overflow: "visible",
      }}
    >
      <Path
        style={{
          fill: "none",
        }}
        d="M3-74.1h72v72H3z"
      />
      <Path
        style={{
          fill: "none",
        }}
        d="M3-74.1h72v72H3z"
      />
      <Path
        style={{
          fill: "none",
        }}
        d="M3-74.1h72v72H3z"
      />
      <Defs>
        <Path id="venue_level3_map_svg__SVGID_1_" d="M3-74.1h72v72H3z" />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000090254092769997596320000007496387629964027554_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_1_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000090254092769997596320000007496387629964027554_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000150808517663811704820000013060839466529410950_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000048502773459601701840000013498423385875526804_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000150808517663811704820000013060839466529410950_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000048502773459601701840000013498423385875526804_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m71-1.3 1.1-2.9"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000097495563447323577750000012145331823301180858_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000165216375215051468690000012055189768587146673_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000097495563447323577750000012145331823301180858_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000165216375215051468690000012055189768587146673_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m66.2-1.1-.7-3.3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000003816692615569220420000001306618698408094121_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000108308286977559865760000014280976034591286690_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000003816692615569220420000001306618698408094121_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000108308286977559865760000014280976034591286690_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m50.9-.3.2-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000133525714941773824350000012673145103272370867_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000131328728097499801960000008203140013444230314_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000133525714941773824350000012673145103272370867_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000131328728097499801960000008203140013444230314_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m35.2-1.9-1.4-2"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000064356205308278791560000001198569332249279873_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000159433055992229367270000017613960117675524019_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000064356205308278791560000001198569332249279873_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000159433055992229367270000017613960117675524019_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m19-1.7 1.3-2"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000035500010401605188380000002959440372852696255_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000075144119723279619030000008187340394261869987_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000035500010401605188380000002959440372852696255_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000075144119723279619030000008187340394261869987_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M7.9-.4 6.2-2.7"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000003085588688095166370000012791769972908690867_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000155858303730303874890000002251670958089061509_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000003085588688095166370000012791769972908690867_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000155858303730303874890000002251670958089061509_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m58.5-.1 2-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000029751666325463697300000000181543608361483163_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000144297517458182680240000002278361810339916476_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000029751666325463697300000000181543608361483163_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000144297517458182680240000002278361810339916476_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M77.8-50.1h-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000039100600316445247890000000689884958620856761_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000002351107045440750730000004502195506006851753_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000039100600316445247890000000689884958620856761_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000002351107045440750730000004502195506006851753_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m76.8-34.4-2 1.5"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000181795843463292927560000014671571238871678344_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000012456928493636786560000002805994153261819810_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000181795843463292927560000014671571238871678344_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000012456928493636786560000002805994153261819810_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m8.8-63.4-2.8 2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000068644003341343467270000008124981231997475481_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000124149610296052123810000015896111218192518803_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000068644003341343467270000008124981231997475481_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000124149610296052123810000015896111218192518803_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M7.3-7.1 5-5.4"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000075881674546406805580000004171367306886690494_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000131368405058507625100000014370763151576180137_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000075881674546406805580000004171367306886690494_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000131368405058507625100000014370763151576180137_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000148650565829423151400000006672659952560023716_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000163050618143571161710000003666846678901968784_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000148650565829423151400000006672659952560023716_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000163050618143571161710000003666846678901968784_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m76.3-67.1-3.3.7"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000175283806766311139910000006713016596473522053_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000113314177493164010190000005691473936477927301_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000175283806766311139910000006713016596473522053_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000113314177493164010190000005691473936477927301_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m75-55.4-2.5 1.8"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000171000072838134728450000015472834746948578945_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000053542306656317147080000010565765161177719229_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000171000072838134728450000015472834746948578945_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000053542306656317147080000010565765161177719229_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M77.8-50.1h-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000003807181985699338630000009535234844763637632_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000007408311423022206490000004440726227985818046_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000003807181985699338630000009535234844763637632_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000007408311423022206490000004440726227985818046_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m74.5-42.6-2.7-1.8"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000030450553447685212180000011323464592699086742_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000121960847462127524850000008373315200433991302_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000030450553447685212180000011323464592699086742_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000121960847462127524850000008373315200433991302_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m76.8-34.4-2 1.5"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000034794279610677219050000001420628092897904025_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000070113929136685169440000014315632485981273249_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000034794279610677219050000001420628092897904025_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000070113929136685169440000014315632485981273249_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m74.3-22.4-2.8-2.2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000026162570073618723860000016655005403226447271_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000125596562082178471630000011019943628057847996_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000026162570073618723860000016655005403226447271_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000125596562082178471630000011019943628057847996_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000142142776613793368760000013558580560721362068_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000054945231112953729400000001763305424891729292_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000142142776613793368760000013558580560721362068_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000054945231112953729400000001763305424891729292_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m76.3-11.4-4.3-.7"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000106126292596091072590000016640030955977947008_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000094590205436441345840000010345484262749686965_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000106126292596091072590000016640030955977947008_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000094590205436441345840000010345484262749686965_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m66.5-13.1-2.2 1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000153679695801019159000000017442077612135018114_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000094577705617881908460000000976630710935737258_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000153679695801019159000000017442077612135018114_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000094577705617881908460000000976630710935737258_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000113319973843142774300000000410280227786950786_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000111893568006336771640000012931820770309814702_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000113319973843142774300000000410280227786950786_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000111893568006336771640000012931820770309814702_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M74.8-29.1h-3"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000087383935044084524480000011511726465675323022_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000183237284582935115060000009951792647489310350_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000087383935044084524480000011511726465675323022_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000183237284582935115060000009951792647489310350_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m61-56.6-1.9-2.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000116223236871704077640000015439732969122045607_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000030476283910560462910000002287719342535651732_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000116223236871704077640000015439732969122045607_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000030476283910560462910000002287719342535651732_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m31-58.1-1.6-2.6"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000018232483651411212280000002334801117663838646_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000152966876389091924500000009990986083708013478_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000018232483651411212280000002334801117663838646_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000152966876389091924500000009990986083708013478_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m46.1-62.5 1.8-2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000176751664520821337200000010913471568818076061_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000173150683180693030320000005794796048708088492_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000176751664520821337200000010913471568818076061_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000173150683180693030320000005794796048708088492_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000165933072857207341350000009128230336981190034_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000088120086139462145290000010249536545349744267_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000165933072857207341350000009128230336981190034_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000088120086139462145290000010249536545349744267_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m71-1.3 1.1-2.9"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000077312461030476589280000002203532792649126544_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000028313043820760636420000017234219636558544027_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000077312461030476589280000002203532792649126544_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000028313043820760636420000017234219636558544027_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m66.2-1.1-.7-3.3"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000160175187959764467100000017081178926548152240_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000062886242984148530730000000555507032164363943_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000160175187959764467100000017081178926548152240_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000062886242984148530730000000555507032164363943_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m60-26.1-1.9-2.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000052078063303506608370000007287679275935233412_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000164497608409353101860000013464071722868052641_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000052078063303506608370000007287679275935233412_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000164497608409353101860000013464071722868052641_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M51.7-54.5 50-57.1"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000029753259831910755290000007338819083597569711_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000061441864499108693570000000073970515753770373_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000029753259831910755290000007338819083597569711_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000061441864499108693570000000073970515753770373_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000066501150855691790840000010553969315041833357_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000053509771760447830490000018022919476422662020_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000066501150855691790840000010553969315041833357_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000053509771760447830490000018022919476422662020_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m50.9-.3.2-3"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000094581584585561143940000017857312284345963190_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000024707166480128947440000002923973453842488762_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000094581584585561143940000017857312284345963190_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000024707166480128947440000002923973453842488762_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m43.6-3.8 1.8-2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000066484490040148140210000004291180041684552103_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000178178730218050329510000002190516142004303501_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000066484490040148140210000004291180041684552103_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000178178730218050329510000002190516142004303501_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000026876994059773393620000003167989659917142148_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000146470215727122115570000000744722608720132250_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000026876994059773393620000003167989659917142148_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000146470215727122115570000000744722608720132250_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m35.2-1.9-1.4-2"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000160172983731372325260000002879088675994873219_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000092443706647057962640000007757424136157399714_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000160172983731372325260000002879088675994873219_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000092443706647057962640000007757424136157399714_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m53.3-33.3 2.4-2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000095324315944050919710000016311932060312747445_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000176758786223068773640000002631465437212453779_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000095324315944050919710000016311932060312747445_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000176758786223068773640000002631465437212453779_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000183229745009775174290000012014752128417269902_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000144306561144831917460000004869501138721213610_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000183229745009775174290000012014752128417269902_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000144306561144831917460000004869501138721213610_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m19-1.7 1.3-2"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000177463505181870337490000012664782082318824889_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000142870272814255059960000018311022647774418058_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000177463505181870337490000012664782082318824889_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000142870272814255059960000018311022647774418058_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m12.3-3.2.9-4.2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000071548170614141943450000006150591121642245007_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000117668907443748804960000000487956266561916088_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000071548170614141943450000006150591121642245007_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000117668907443748804960000000487956266561916088_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000145043090491677694050000015987514684733275318_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000135677625019211764690000012615760089478186121_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000145043090491677694050000015987514684733275318_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000135677625019211764690000012615760089478186121_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M7.9-.4 6.2-2.7"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000008851763804007932040000017485088752505367207_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000000940406470268647210000004805616377468349320_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000008851763804007932040000017485088752505367207_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000000940406470268647210000004805616377468349320_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m30.1-4.1.1-3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000067200440408271875920000013134774617039778491_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000076585684347660946890000011306327019121569185_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000067200440408271875920000013134774617039778491_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000076585684347660946890000011306327019121569185_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m10.3-68.9 3.5 1.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000127004889745166134050000010287765443867794053_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000063591057775446642220000003926636068748738466_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000127004889745166134050000010287765443867794053_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000063591057775446642220000003926636068748738466_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m7.5-53.4.3-3.2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000150801891432760610110000005803878011151194034_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000054974302926253752250000004422684026714720935_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000150801891432760610110000005803878011151194034_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000054974302926253752250000004422684026714720935_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m11-30.4 1.5 3.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000130629836125153762580000011720276223206917774_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000155129987365532881510000013948439970637043892_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000130629836125153762580000011720276223206917774_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000155129987365532881510000013948439970637043892_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m8.3-35.9 2.2-3.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000031922134059205299000000008144481472879807360_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000036217261402481375470000002512766406351439529_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000031922134059205299000000008144481472879807360_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000036217261402481375470000002512766406351439529_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M32.3-69.6 30-67.1"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000052079712722545893670000014472497701792739457_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000080918853767868239690000009065511842377709207_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000052079712722545893670000014472497701792739457_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000080918853767868239690000009065511842377709207_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m36.5-69.6 3.3 2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000112618601001958821200000013550743373118152580_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000072977426997379683140000005144705612586747794_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000112618601001958821200000013550743373118152580_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000072977426997379683140000005144705612586747794_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m53.3-69.6 3.2 2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000069378568952519982010000011528408591671351475_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000059296845656842688010000010785651547680430222_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000069378568952519982010000011528408591671351475_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000059296845656842688010000010785651547680430222_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m55.5-63.6-2 3.2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000076561237700490939250000006736892221193919925_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000081607893133279944980000000828521020069058988_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000076561237700490939250000006736892221193919925_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000081607893133279944980000000828521020069058988_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m62.5-64.9 5-2.2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000065785090013495797770000006933014295476692630_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000103967358200674750700000016798985351909258632_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000065785090013495797770000006933014295476692630_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000103967358200674750700000016798985351909258632_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m70-61.4-2.5 1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000085936984040649085440000007931092567107691905_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000110464089713937424000000005072275713922958217_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000085936984040649085440000007931092567107691905_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000110464089713937424000000005072275713922958217_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M21.8-69.4v3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000040554441610787201940000013888753053807130253_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000086669761348291397580000007597461983894352802_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000040554441610787201940000013888753053807130253_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000086669761348291397580000007597461983894352802_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m18.8-64.4-3.3.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000134962135403666833770000018110303731130804111_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000047764731966755867580000012799881897332263827_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000134962135403666833770000018110303731130804111_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000047764731966755867580000012799881897332263827_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m23-59.9.5 3.3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000042703972527937462720000009381987852331791747_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000028303532039122695530000003309530247881939388_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000042703972527937462720000009381987852331791747_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000028303532039122695530000003309530247881939388_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m24-64.4 3.5-.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000107570968004504966280000005870127690269310368_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000107580288312849914660000011706595168202374817_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000107570968004504966280000005870127690269310368_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000107580288312849914660000011706595168202374817_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m12.3-52.6 2.7-2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000138545307367656281200000016611363192264915084_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000107586725708598941540000000937368719715913116_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000138545307367656281200000016611363192264915084_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000107586725708598941540000000937368719715913116_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m10.5-10.1.3-4"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000088095338862664845600000009632571440681225103_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000149369033103976228540000012040703916286174358_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000088095338862664845600000009632571440681225103_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000149369033103976228540000012040703916286174358_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m10.3-18.4 2.2-2.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000040549829905478084010000004473001562349972635_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000125560904631082990490000006813632220942465451_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000040549829905478084010000004473001562349972635_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000125560904631082990490000006813632220942465451_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m24.3-8.4-2.5-3.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000065049382474762278820000008593345986927346080_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000016045385211974496530000011816113160016102557_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000065049382474762278820000008593345986927346080_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000016045385211974496530000011816113160016102557_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m32.8-45.6-2.5 1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000161604683414428890010000016361921018145077682_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000052072647701178368540000017639993070936408231_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000161604683414428890010000016361921018145077682_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000052072647701178368540000017639993070936408231_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M60.8-40.4 58-41.9"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000147911312810222342280000011207180394694161307_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000038371467523082479640000007309909109595143353_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000147911312810222342280000011207180394694161307_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000038371467523082479640000007309909109595143353_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m29.8-34.1-3.8-.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000046317659524300593870000017429031665734263981_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000141421925437048157580000003037141171212358317_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000046317659524300593870000017429031665734263981_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000141421925437048157580000003037141171212358317_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m34.5-24.6-2 1.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000021095215651505187970000001918588930834044294_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000150083834030153131910000006055237723528332465_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000021095215651505187970000001918588930834044294_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000150083834030153131910000006055237723528332465_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m32-12.6-2.7-2.3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000041290074927067229290000017818349796823390086_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000026146377710457959510000010789040464258026165_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000041290074927067229290000017818349796823390086_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000026146377710457959510000010789040464258026165_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m35.3-8.4-2-1.2"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000142892413949083813130000001289234557740883841_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000011751303825950991100000000673078751837732532_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000142892413949083813130000001289234557740883841_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000011751303825950991100000000673078751837732532_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M32.5-19.4h-3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000132799657026130788930000002848700414225481130_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000167394995721010046360000010309758016103618432_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000132799657026130788930000002848700414225481130_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000167394995721010046360000010309758016103618432_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m42.8-55.1-2.5 1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000073678906448222982720000011011068594005135289_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000028286330314391040190000001782258400301392788_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000073678906448222982720000011011068594005135289_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000028286330314391040190000001782258400301392788_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M45.5-49.9h-3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000129195320316752553270000014335431121128404905_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000028297969453303047310000004486978056671972792_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000129195320316752553270000014335431121128404905_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000028297969453303047310000004486978056671972792_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m42.3-42.4-2.8-1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000013906102953022136350000008443764161628493728_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000072269451467386784060000004979753140281923748_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000013906102953022136350000008443764161628493728_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000072269451467386784060000004979753140281923748_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m41.5-31.6-.7 2.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000099660346710089485020000006499731478314287289_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000093870757414278739340000009130196257712596370_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000099660346710089485020000006499731478314287289_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000093870757414278739340000009130196257712596370_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m42-22.1-2.7-2.3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000085234065452257939340000006315732683650988698_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000124841432190816355170000010103179581540112305_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000085234065452257939340000006315732683650988698_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000124841432190816355170000010103179581540112305_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m42.5-14.1-2-1.3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000056421005231019569970000008728168182433296815_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000032607882655470603580000014579288997814234556_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000056421005231019569970000008728168182433296815_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000032607882655470603580000014579288997814234556_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M66-29.6h-3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000014619283313154991310000017371129569882071456_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000179633643501894540600000004433267906163767702_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000014619283313154991310000017371129569882071456_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000179633643501894540600000004433267906163767702_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m25.3-31.1 2.5 1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000114045264314893775610000013133176083773514387_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000061445943994919057820000006094118375777752963_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000114045264314893775610000013133176083773514387_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000061445943994919057820000006094118375777752963_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M30-53.6h3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000041258308664085000750000007081690837359489461_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000119104681403095758970000015409417733729201054_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000041258308664085000750000007081690837359489461_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000119104681403095758970000015409417733729201054_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m17.8-39.4 2.5 1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000111186710687660334090000017281672302850632589_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000101817143447448350210000010211979567878536874_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000111186710687660334090000017281672302850632589_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000101817143447448350210000010211979567878536874_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M15-34.1h3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000109751284536785428830000013724156847500089534_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000080927373195797630570000016866562705859042211_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000109751284536785428830000013724156847500089534_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000080927373195797630570000016866562705859042211_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m18.3-26.6 2.7-1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000044156467126955058550000001997828882125476790_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000148636278958783932070000017092092059236870846_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000044156467126955058550000001997828882125476790_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000148636278958783932070000017092092059236870846_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m50.3-41.1 2.4-1.6"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000114068390453362993580000016329853080867114917_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000044865970093898672130000006449544743078585023_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000114068390453362993580000016329853080867114917_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000044865970093898672130000006449544743078585023_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m50.6-48.6 2.4 1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000066497791515657756280000016137787583810329733_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000101088081407222979890000017953218090382794898_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000066497791515657756280000016137787583810329733_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000101088081407222979890000017953218090382794898_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m57.7-52.6-.2 3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000090258795739570437390000003170793619218107299_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000071527497755171105310000012860098646617127833_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000090258795739570437390000003170793619218107299_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000071527497755171105310000012860098646617127833_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m48.4-8.5 3 .1"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000165925868708532643770000001629204930292068489_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000087405537490966285600000008994755123611510144_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000165925868708532643770000001629204930292068489_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000087405537490966285600000008994755123611510144_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m49.4-14.4 2.3 1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000171684453883110917880000005200766018907682436_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000096033317340822931240000016165647402808930468_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000171684453883110917880000005200766018907682436_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000096033317340822931240000016165647402808930468_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m56.5-18.4 1.1 3.1"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000067937195550716827140000017246724880076355762_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000158730174627589109540000011186964200341574300_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000067937195550716827140000017246724880076355762_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000158730174627589109540000011186964200341574300_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m51.3-30.6-2.5 1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000176031833531110494100000009803767020202862241_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000096743272445687491080000013036356894655201184_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000176031833531110494100000009803767020202862241_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000096743272445687491080000013036356894655201184_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m51-23.1-3.2-1"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000057110088318051547300000012779214509818751887_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000177479111280822834110000010906153122038119567_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000057110088318051547300000012779214509818751887_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000177479111280822834110000010906153122038119567_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m60-7.6-2.7-1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000099647065799731385290000018036836849840438431_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000050622437709928370510000001563000131975144612_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000099647065799731385290000018036836849840438431_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000050622437709928370510000001563000131975144612_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m67.5-33.1-2.7-1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000132088733801392086210000009856468968787076232_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000176740205377931802560000012409631619252265107_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000132088733801392086210000009856468968787076232_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000176740205377931802560000012409631619252265107_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m65.3-22.1-2 1.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000080206357331733663940000016764802340431568822_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000113349064028815243210000002005148454157486761_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000080206357331733663940000016764802340431568822_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000113349064028815243210000002005148454157486761_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m22.6-44.5 3.1.1"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000013154122053249839550000008694221495427153031_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000090274697050368444400000007679982047276646838_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000013154122053249839550000008694221495427153031_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000090274697050368444400000007679982047276646838_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m23.6-50.4 2.4 1.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000090263601829491623270000009255194492409252521_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000175295461884517856780000002728741860351426722_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000090263601829491623270000009255194492409252521_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000175295461884517856780000002728741860351426722_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m60.8-45.6 2.7.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000158002381515165744030000000115781427890337686_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000145754105257707034720000013609265796764969345_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000158002381515165744030000000115781427890337686_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000145754105257707034720000013609265796764969345_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m18-18.4.5 3.3"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000094604784205807051310000014411536112785416606_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000032617823905445852890000014360872173430205617_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000094604784205807051310000014411536112785416606_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000032617823905445852890000014360872173430205617_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m20-20.4 3.5-.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000051360603835489172810000006774556221776743561_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000130629892785419713670000008046232653354644877_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000051360603835489172810000006774556221776743561_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000130629892785419713670000008046232653354644877_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m35.8-61.4 4 .8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000155114980898072213780000016302505981227234739_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000135656777602004136630000014951568744980796310_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000155114980898072213780000016302505981227234739_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000135656777602004136630000014951568744980796310_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m36.5-40.6.5 3.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000095321547722121113290000014123287114659464323_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000145772194204383724660000002844270543288115603_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000095321547722121113290000014123287114659464323_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000145772194204383724660000002844270543288115603_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="M67.5-51.1 65-52.4"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000178192576993086888430000008005986336017470391_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000081642250933672284300000003118222199865507741_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000178192576993086888430000008005986336017470391_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000081642250933672284300000003118222199865507741_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m13-60.6 3.3 2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000060752679841322980350000017831158144633518267_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000013902785370063467740000011175892738701949090_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000060752679841322980350000017831158144633518267_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000013902785370063467740000011175892738701949090_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m7.8-44.6-2.5 1.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000049208159650934143530000009154726192437556640_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000125594961014133837550000012467775594996278714_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000049208159650934143530000009154726192437556640_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000125594961014133837550000012467775594996278714_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m18.8-50.1-2 1.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000154384558997517583280000004799973504877803951_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000026130807561157755860000005150378509468199089_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000154384558997517583280000004799973504877803951_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000026130807561157755860000005150378509468199089_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m44.3-69.3 1.9-2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000171699669289174751630000008050191993719246735_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000114043130881079307540000006888044190113364877_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000171699669289174751630000008050191993719246735_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000114043130881079307540000006888044190113364877_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m67.6-39 1.8-2.7"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000141437451194061487280000015669925270963845299_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000075869157891418593250000010334485151785134234_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000141437451194061487280000015669925270963845299_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000075869157891418593250000010334485151785134234_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m36.8-47.8.7-2.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000036969347398014858550000012016538976667807166_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000183948803383826174990000009123038219370414762_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000036969347398014858550000012016538976667807166_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000183948803383826174990000009123038219370414762_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m23.3-15.3.7-2.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000018198807799004940190000018248833808967196320_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000054965247977987703140000010481775849416696505_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000018198807799004940190000018248833808967196320_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000054965247977987703140000010481775849416696505_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m34.8-31.4-2 1.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000112624743707214621490000004468188872186306945_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000109749008393388048630000015509224263330927288_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000112624743707214621490000004468188872186306945_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000109749008393388048630000015509224263330927288_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000106119137024194912100000011225784514970372798_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000091717989908320936610000000969597282800382337_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000106119137024194912100000011225784514970372798_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000091717989908320936610000000969597282800382337_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M5.8-50.1h-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000066481556695579749480000017977157341551106981_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000039119934100679008480000017650141432404455600_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000066481556695579749480000017977157341551106981_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000039119934100679008480000017650141432404455600_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m4.8-34.4-2 1.5"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000164502774520754741730000009365958492619512203_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000019676202120775264550000004140140897279312319_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000164502774520754741730000009365958492619512203_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000019676202120775264550000004140140897279312319_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m71-73.3 1.1-2.9"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000071561328342175176190000013058417593904011940_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000027574347958553348700000008197259213400477086_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000071561328342175176190000013058417593904011940_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000027574347958553348700000008197259213400477086_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m66.2-73.1-.7-3.3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000083065681420109160190000002230277992460568740_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000127045619693428648930000014518235030029249695_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000083065681420109160190000002230277992460568740_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000127045619693428648930000014518235030029249695_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m50.9-72.3.2-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000067955472568550503520000007744070835542787219_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000069387321934251393530000014851830331070946725_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000067955472568550503520000007744070835542787219_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000069387321934251393530000014851830331070946725_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m35.2-73.9-1.4-2"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000111156583007395682790000002331213927642854276_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000132056258247304977200000000933666184681887130_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000111156583007395682790000002331213927642854276_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000132056258247304977200000000933666184681887130_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m19-73.7 1.3-2"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000077291677431161201640000016854290037785280654_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000181773164599849424740000009185587597052012430_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000077291677431161201640000016854290037785280654_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000181773164599849424740000009185587597052012430_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m7.9-72.4-1.7-2.3"
          />
        </G>
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000092416960471168797790000016558731151811334567_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000036250859840355601180000004479632299249263501_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000092416960471168797790000016558731151811334567_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000036250859840355601180000004479632299249263501_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m8.3-22.1-3.8-.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000034810224207727392820000009542126872667506577_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000021812451760972339430000004526971105890809776_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000034810224207727392820000009542126872667506577_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000021812451760972339430000004526971105890809776_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m71.4-16.8-1.9-2.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000116220476610976124450000013447263279204200579_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000094586839009670900090000009443605588951860894_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000116220476610976124450000013447263279204200579_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000094586839009670900090000009443605588951860894_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m27-38.1.7-2.8"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000060004164219691026280000014783144626087887794_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000106149089054671354300000004932840702575947938_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000060004164219691026280000014783144626087887794_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000106149089054671354300000004932840702575947938_)",
        }}
      >
        <Path
          style={{
            fill: "none",
            stroke: "#1d1d1b",
            strokeWidth: 0.3,
          }}
          d="m47.8-37.1-2 1.5"
        />
      </G>
      <Defs>
        <Path
          id="venue_level3_map_svg__SVGID_00000060008451709360678960000001762577378468497555_"
          d="M3-74.1h72v72H3z"
        />
      </Defs>
      <ClipPath id="venue_level3_map_svg__SVGID_00000077311013809076432580000002006077742364059574_">
        <Use
          xlinkHref="#venue_level3_map_svg__SVGID_00000060008451709360678960000001762577378468497555_"
          style={{
            overflow: "visible",
          }}
        />
      </ClipPath>
      <G
        style={{
          clipPath:
            "url(#venue_level3_map_svg__SVGID_00000077311013809076432580000002006077742364059574_)",
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000157994902752911549460000009604438726623116441_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000164503067018682630280000008732405764724592293_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000157994902752911549460000009604438726623116441_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000164503067018682630280000008732405764724592293_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m58.5-72.1 2-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000003080262871839212880000015825805253647839125_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000122699433042725104170000005269316030343938974_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000003080262871839212880000015825805253647839125_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000122699433042725104170000005269316030343938974_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m58.5-.1 2-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000124883335075382433610000009610587666331683749_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000109711892036417476130000007560728609806092930_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000124883335075382433610000009610587666331683749_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000109711892036417476130000007560728609806092930_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m4.3-67.1-3.3.7"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000173138915386752715750000000853003319484363663_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000092418387070393262450000017560987230436891799_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000173138915386752715750000000853003319484363663_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000092418387070393262450000017560987230436891799_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M3-55.4.5-53.6"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000052823197287536426010000014520680809374435750_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000175320882328212654590000007333648330226236548_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000052823197287536426010000014520680809374435750_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000175320882328212654590000007333648330226236548_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M5.8-50.1h-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000155121600561965348260000013359371473762830780_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000096765277340919893890000018234890696243541654_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000155121600561965348260000013359371473762830780_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000096765277340919893890000018234890696243541654_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m4.8-34.4-2 1.5"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000121280967395943810850000003026983742933028787_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000054241929882461105120000017391084490327913128_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000121280967395943810850000003026983742933028787_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000054241929882461105120000017391084490327913128_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="M4.3-11.4 0-12.1"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000101808948172224810570000007982244977858178978_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000092434759551350313070000007797849017744579495_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000101808948172224810570000007982244977858178978_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000092434759551350313070000007797849017744579495_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m71-73.3 1.1-2.9"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000151509325515384445750000001816030928412971927_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000020362839357073039340000007470640835166721708_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000151509325515384445750000001816030928412971927_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000020362839357073039340000007470640835166721708_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m66.2-73.1-.7-3.3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000063603580043729086290000006268311409472377275_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000041997195024699150530000003575343891318357654_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000063603580043729086290000006268311409472377275_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000041997195024699150530000003575343891318357654_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m50.9-72.3.2-3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000106847531885663576280000000382394525761231270_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000151518271878521038140000017600019022612404898_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000106847531885663576280000000382394525761231270_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000151518271878521038140000017600019022612404898_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m35.2-73.9-1.4-2"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000031195287274020856730000008233383002128501646_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000109002631099081569080000006426496343759891107_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000031195287274020856730000008233383002128501646_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000109002631099081569080000006426496343759891107_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m19-73.7 1.3-2"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000043414946844118189320000016183052281790263724_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000113335317542559682520000009029772930486238135_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000043414946844118189320000016183052281790263724_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000113335317542559682520000009029772930486238135_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m7.9-72.4-1.7-2.3"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000010990307923562235780000006407460850111281314_"
            d="M3-74.1h72v72H3z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000176724691777188203700000017345792252987091083_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000010990307923562235780000006407460850111281314_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000176724691777188203700000017345792252987091083_)",
          }}
        >
          <Path
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.3,
            }}
            d="m58.5-72.1 2-3"
          />
        </G>
      </G>
    </Pattern>
    <G id="venue_level3_map_svg__base">
      <Path
        style={{
          fill: "#fff",
        }}
        d="m1113.2 627.3-110.3-573-50.1-6.5-92.4 62.5-153.6-56-107.7 573H472.7V705h767v-77.7z"
      />
      <Path
        style={{
          fill: "#ded6ea",
        }}
        d="m548.6 729.5-65.1.9-3.8 2.8-1 46.9 9.6 13.1v153.2H654v-141h-24.1l-6.6-49.7-69.9 10.3z"
      />
      <Path
        style={{
          fill: "none",
          stroke: "#978ca5",
          strokeMiterlimit: 10,
        }}
        d="m548.6 729.5-65.1.9-3.8 2.8-1 46.9 9.6 13.1v153.2H654v-141h-24.1l-6.6-49.7-69.9 10.3z"
      />
      <G
        style={{
          opacity: 0.5,
        }}
      >
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000043435259431782531100000005874274379849764487_"
            d="M478.2 728.9h176.3v217.9H478.2z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000033337443661998919730000015995164872280508828_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000043435259431782531100000005874274379849764487_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000033337443661998919730000015995164872280508828_)",
          }}
        >
          <Pattern
            id="venue_level3_map_svg__SVGID_00000168114024387750178260000015457539841394278817_"
            xlinkHref="#venue_level3_map_svg__Unnamed_Pattern"
            patternTransform="translate(212.7 18271.09)"
          />
          <Path
            style={{
              fill: "url(#venue_level3_map_svg__SVGID_00000168114024387750178260000015457539841394278817_)",
            }}
            d="m483.5 730.4-3.8 2.8-1 46.9 9.6 13.1v153.2H654v-141h-24.1l-6.6-49.7-69.9 10.3-4.8-36.5z"
          />
        </G>
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000002349489252744916470000004907380399985449619_"
            d="M478.2 728.9h176.3v217.9H478.2z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000088094501203680359440000002653177025857222558_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000002349489252744916470000004907380399985449619_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000088094501203680359440000002653177025857222558_)",
          }}
        >
          <Defs>
            <Path
              id="venue_level3_map_svg__SVGID_00000039120054178338507720000000626413749708554124_"
              d="M478.2 728.9h176.3v217.9H478.2z"
            />
          </Defs>
          <ClipPath id="venue_level3_map_svg__SVGID_00000137823530612102276080000006006786430016309936_">
            <Use
              xlinkHref="#venue_level3_map_svg__SVGID_00000039120054178338507720000000626413749708554124_"
              style={{
                overflow: "visible",
              }}
            />
          </ClipPath>
          <G
            style={{
              clipPath:
                "url(#venue_level3_map_svg__SVGID_00000137823530612102276080000006006786430016309936_)",
            }}
          >
            <Path
              style={{
                fill: "none",
                stroke: "#978ca5",
                strokeMiterlimit: 10,
              }}
              d="m548.6 729.5-65.1.9-3.8 2.8-1 46.9 9.6 13.1v153.2H654v-141h-24.1l-6.6-49.7-69.9 10.3z"
            />
          </G>
        </G>
      </G>
      <Path
        d="m1137.4 639.1-90.8-6-133.9 133.3-90.1.3L719.9 777l-16.2 3-15.3 7.5-4.7-43.5-.5-27.9 2.3-32.3 7.8-37-126.2 22.1-98.4 71.2v209.4h350v-52.1l67.2-.1 62.2 45.4 68.6 3.9 61.5-8.7 72 9.4 93.5-34.6V722.1l-106.3-83zM907 894.5h-94.3v-87.7h70.6l23.7 3.3v84.4z"
        style={{
          fill: "#fefaf8",
        }}
      />
      <Path
        style={{
          fill: "#ededed",
        }}
        d="m635.5 696.2 21.7-3.8 15 88.6-21.6 3.6zM1059.8 784.8l-20.5-3.3 14.8-89.2 20.2 3.9z"
      />
      <Path
        style={{
          fill: "#ededed",
        }}
        d="m1053.7 691.9 20.7 3.4-15.1 90.4-20.6-3.3z"
      />
      <Path
        style={{
          fill: "none",
          stroke: "#cdcfd0",
          strokeWidth: 0.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="m1053.7 691.9 20.7 3.4-15.1 90.4-20.6-3.3z"
      />
      <Path
        style={{
          fill: "#ededed",
        }}
        d="m635.2 695.3 21.9-3.8 15.2 90.2-21.6 3.9z"
      />
      <Path
        style={{
          fill: "none",
          stroke: "#cdcfd0",
          strokeWidth: 0.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="m635.2 695.3 21.9-3.8 15.2 90.2-21.6 3.9z"
      />
      <Path
        d="M663.7 738.8c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10"
        style={{
          fill: "#ececec",
        }}
      />
      <Path
        d="M663.7 738.8c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10"
        style={{
          fill: "none",
          stroke: "#000",
          strokeMiterlimit: 10,
        }}
      />
      <Path
        d="M652.6 734.8c.5 0 1-.5 1-1.1s-.4-1.1-1-1.1c-.5 0-1 .5-1 1.1s.5 1.1 1 1.1"
        style={{
          fill: "#010000",
        }}
      />
      <Path
        d="M653.6 736.8v-.7c0-1.5-2-1.5-2 0v2.8l-1.9 2.1c-.1.1-.3.2-.5.2h-1.1c-1.1 0-1.8 1.1-1.8 2.1s.7 2.2 1.9 2.2h2c.5 0 1.1-.2 1.5-.7l5.9-6.6c.1-.1.2-.2.4-.2h1.5c.7 0 1.7-.9 1.7-2.2s-1-2.1-1.8-2.1h-2.3c-.5 0-1.1.2-1.5.6l-2 2.5z"
        style={{
          fill: "#010000",
        }}
      />
      <Path
        d="M659 740.3h-1.9c-.5 0-.6.9 0 .9h1l-1.9 2.1c-.4.5.2 1.1.6.6l1.9-2.1v1.1c0 .6.8.6.8 0v-2.1c0-.3-.2-.5-.5-.5"
        style={{
          fill: "#1d1d1b",
        }}
      />
      <Path
        d="M657.2 734.9h2.1c.5 0 .8.6.8 1s-.2 1.1-.9 1.1H658c-.5 0-.8.1-1.1.4l-6 6.6c-.2.3-.6.4-.9.4h-2c-.5 0-1-.5-1-1.1s.4-1 .9-1h1.4c.4 0 .7-.1.9-.4l6.1-6.7c.2-.3.5-.3.9-.3"
        style={{
          fill: "#fff",
        }}
      />
      <Path
        d="M1066.6 738.8c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10"
        style={{
          fill: "#ececec",
        }}
      />
      <Path
        d="M1066.6 738.8c0 5.5-4.5 10-10 10s-10-4.5-10-10 4.5-10 10-10 10 4.5 10 10"
        style={{
          fill: "none",
          stroke: "#000",
          strokeMiterlimit: 10,
        }}
      />
      <Path
        d="M1055.4 734.8c.6 0 1-.5 1-1.1s-.4-1.1-1-1.1c-.5 0-1 .5-1 1.1.1.6.5 1.1 1 1.1"
        style={{
          fill: "#010000",
        }}
      />
      <Path
        d="M1056.4 736.8v-.7c0-1.5-2-1.5-2 0v2.8l-1.9 2.1c-.1.1-.3.2-.5.2h-1.1c-1.1 0-1.8 1.1-1.8 2.1s.7 2.2 1.9 2.2h2c.5 0 1.1-.2 1.5-.7l5.9-6.6c.1-.1.2-.2.4-.2h1.5c.7 0 1.7-.9 1.7-2.2s-1-2.1-1.8-2.1h-2.3c-.5 0-1.1.2-1.5.6l-2 2.5z"
        style={{
          fill: "#010000",
        }}
      />
      <Path
        d="M1061.8 740.3h-1.9c-.5 0-.6.9 0 .9h1l-1.9 2.1c-.4.5.2 1.1.6.6l1.9-2.1v1.1c0 .6.8.6.8 0v-2.1c0-.3-.2-.5-.5-.5"
        style={{
          fill: "#1d1d1b",
        }}
      />
      <Path
        d="M1060 734.9h2.1c.5 0 .8.6.8 1s-.2 1.1-.9 1.1h-1.2c-.5 0-.8.1-1.1.4l-6 6.6c-.2.3-.6.4-.9.4h-2c-.5 0-1-.5-1-1.1s.4-1 .9-1h1.4c.4 0 .7-.1.9-.4l6.1-6.7c.3-.3.5-.3.9-.3"
        style={{
          fill: "#fff",
        }}
      />
      <Path
        d="m887 758.2.4-.4c1-1 1.5-2.3 1.5-3.7v-53.7c0-1.2-.4-2.3-1.1-3.2l-.8-1c-1-1.2-2.5-1.9-4.1-1.9h-56.6c-1.4 0-2.7.5-3.7 1.5s-1.5 2.3-1.5 3.6l-.4 54c0 1.8.9 3.4 2.3 4.4l.9.6c.8.6 1.8.9 2.9.9h5.2c.9 0 1.7.8 1.7 1.7v4c0 .9.8 1.7 1.7 1.7h35.1c.9 0 1.7-.8 1.7-1.7v-3.5c0-.9.8-1.7 1.7-1.7h9.2c1.6 0 2.9-.6 3.9-1.6z"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
      />
      <G id="venue_level3_map_svg__pillars">
        <Path
          style={{
            fill: "#231f20",
          }}
          d="m558.3 705.2-.2.5-2 .3.4 2.1 1.9-.3.3.2 3.8-.7-.4-2.7zM487.7 881h2.9v9.6h-2.9z"
        />
        <Path
          transform="rotate(-.22 489.245 846.158)"
          style={{
            fill: "#231f20",
          }}
          d="M487.8 843.3h2.9v5.7h-2.9z"
        />
        <Path
          transform="rotate(-.22 489.327 776.674)"
          style={{
            fill: "#231f20",
          }}
          d="M487.9 774.3h2.9v4.7h-2.9z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="M554.9 886v4.7l4.7-.1-.1-4.6zM699.7 777.4l.5 4.3 4.2-.6v-.6l-.1-.1V779.5l-.1-.1V778.8l-.1-.1V778l-.1-.1v-.6l-.1-.1v-.5zM706.6 815.8l.2 1.6v.8l.5 3.7 2.9-.5-.3-2v-.4l-.1-1.2-.1-.2-.2-2.2zM688 886.1v4.6h4.6v-4.6zM688.3 819l.4 2.4h-.1l.1.3v.1l.2 1.4h.1l.4 1.9 2.9-.6-.5-2.4-.6-3.6zM644.9 827.7l.6 3.2 3.2-.6v-.1l-.1-.1V829.8l-.1-.1v-.4l-.1-.1v-.4l-.1-.1v-.4l-.1-.1v-.5l-.1-.1v-.5zM670.7 691.5l.5 4.2 4.2-.6-.6-4.2zM631.6 760.2l.5 3.3 3.9-.6-.6-3.3zM626.4 729l.5 3.2 3.8-.6-.4-3.1zM622 697.5l.4 3.2 3.9-.4-.4-3.2zM619.1 666l.3 3.1 3.9-.4-.3-3.1zM590.6 700.9l.3 3.8 2.7-.3-.3-3.8zM595 733.5l.6 3.8 2.7-.4-.6-3.8zM600.3 765.9l.7 3.8 2.7-.5-.7-3.8zM606.8 798.2l.8 3.8 2.6-.6-.8-3.8zM583.2 838.8l1 3.9-2.4.7 1.3 6.1 5-1.4-.6-2.5 1.1-.2-1.9-7.3zM574.8 805.9l.7 2.6 3.9-.9-.6-2.6zM565.4 773l1.3 6 3-.6-.8-3.2 3.9-.8-.6-2.8z"
        />
        <Path
          transform="rotate(-9.105 564.97 740.13)"
          style={{
            fill: "#231f20",
          }}
          d="M562.8 738.7h4.1v2.7h-4.1z"
        />
        <Path
          transform="rotate(-12.902 639.826 792.446)"
          style={{
            fill: "#231f20",
          }}
          d="M638.2 791.2h3.5v2.8h-3.5z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="m1067.9 790.6.7-3.2-.9-.2-2.1 9.2.9.2.7-3.2 3.8.8.6-2.7zM1217.8 774.5l.1 4.6h2.8v-4.7zM1217.8 843.3l.1 5.9 2.9-.1-.1-5.9zM1147.5 705l-.2 2.5 4 .4.4-.2 1.8.1.2-2.2zM1143.1 738.4l-.4 2.7 3.9.5.3-2.7zM1141.3 772.2l-.1.3-3.7-.7-.6 2.6 3.8.7-.7 3.2 3 .6 1.3-6.1zM1130.7 805.1l-.6 2.6 3.8.9.5-2.6zM1100.3 797.5l-.9 3.7 2.5.7.9-3.8zM1106.7 765.4l-.7 3.7 2.9.6.7-3.7zM1112.1 733l-.6 3.8 2.9.6.6-4zM1116.4 700.5l-.5 3.8 3 .3.5-3.8zM1074.4 759.6l-.6 3.2 3.8.6.6-3.1zM1079.7 728.3l-.5 3.2 3.8.6.5-3.1zM1083.8 697l-.4 3.1 3.9.6.4-3.2zM1092.5 665.4l-.4 3.3 3.9.4.3-3.3z"
        />
        <Path
          transform="rotate(-80.177 1026.723 782.865)"
          style={{
            fill: "#231f20",
          }}
          d="M1024.5 780.8h4.3v4h-4.3z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="m1006.4 776.8-.8 5.6 4.2.6.8-5.6z"
        />
        <Path
          transform="rotate(-84.99 961.473 773.652) scale(1.00002)"
          style={{
            fill: "#231f20",
          }}
          d="M959.3 772.1h4.3v3.1h-4.3z"
        />
        <Path
          transform="rotate(-84.99 942.69 771.61) scale(1.00002)"
          style={{
            fill: "#231f20",
          }}
          d="M940.5 770h4.3v3.2h-4.3z"
        />
        <Path
          transform="matrix(.05007 -.9987 .9987 .05007 83.553 1625.804)"
          style={{
            fill: "#231f20",
          }}
          d="M894.3 767h4.3v4h-4.3z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="m875.7 804.9-.1 2.4v3.9h2.8v-3.3l.2-2.9zM876.5 765.7l-.1 4.6 2.9.1.1-4.7z"
        />
        <Path
          transform="rotate(-2.292 812.657 768.69)"
          style={{
            fill: "#231f20",
          }}
          d="M810.7 766.5h4v4.3h-4z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="m830.3 765.9.2 4.4 2.9-.1-.2-4.4z"
        />
        <Path
          transform="matrix(1 -.00838 .00838 1 -6.726 7.004)"
          style={{
            fill: "#231f20",
          }}
          d="M831.3 805h2.9v2.8h-2.9z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="m632.4 829-5.6 1.3V885h-2.9v5.7h11.4v-5.8l-2.9.1zM634.8 789.1l7.1-1.7 2 8.8-7.1 1.5zM1072.6 798.1l-7-1.7 2.1-9.2 7 1.6z"
        />
        <Path
          transform="rotate(-11.783 569.195 775.023)"
          style={{
            fill: "#231f20",
          }}
          d="M565.4 771.9h7.9v6.5h-7.9z"
        />
        <Path
          style={{
            fill: "#231f20",
          }}
          d="M749.9 886.1v4.6h4.5l.1-4.6zM750.3 810.5l.2 2-.1.2.4 3.7 2.8-.3-.3-3.8-.1-2.1zM769 808.3l.2 2.4-.1.1.1 1.5.1.1.2 2 2.8-.3-.2-2.1.1-.7-.3-3.3z"
        />
      </G>
    </G>
    <G id="venue_level3_map_svg__rooms">
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        style={{
          fill: "#f6bbb0",
        }}
        d="M812.7 806.7v87.8H907v-84.4l-23.7-3.4z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1091"
        style={{
          fill: "#f6bbb0",
        }}
        d="m897.8 835.2 3.4-26.2 94 10 82.5 15.5 46.7-.7-1 88-42.7 18.8-64 6.4-50.8-1.2-33.8-8.5z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1095"
        style={{
          fill: "#f6bbb0",
        }}
        d="m593.1 301.5 64.8 19.7-12.6 39.9-11.6 54.3-10 66.8-68-9.7 10.2-63.1 15.5-69.6z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1094"
        style={{
          fill: "#f6bbb0",
        }}
        d="m553.3 501.8 67.4 5.5-2.8 38.6-1.3 58.2-66.5 2.4.1-53 2-35.5z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1100"
        style={{
          fill: "#d8a9ed",
        }}
        d="m1094 607.6-.6-30.7-1.7-37.7 67.1-3.3 1.6 37.9-.5 33.6z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1090"
        style={{
          fill: "#f6bbb0",
        }}
        d="m1090.1 510.1-5.1-36.5-5.3-34.5-3.3-19.5-14.8-69.4 63.7-17.7 11.4 45.5 10.9 56.2 6.6 43.8 2.4 25.6z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1092"
        style={{
          fill: "#f6bbb0",
        }}
        d="m752.1 772.8 48.3-5 14-9.8 3-71.7-19.5-11.2-66.5-8.9-39.5 6.3-5.9 7.2-1.8 18.3-1 14.9.1 16.1 1.1 22.3 1.5 15.2 11.3 15.1 26.7-5.3z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1176"
        style={{
          fill: "#889fe6",
        }}
        d="m603.2 270.8 26.2-65.8 61 26.5-11.5 29.5-12.4 29.6z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1181"
        style={{
          fill: "#889fe6",
        }}
        d="m1052.6 321.8-8-23.7-12.6-38.2 61.4-22.1 13.6 33.5 8.9 32.3z"
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        d="m1255.6 719.3 8.6-1.8-28.6-133.6h-.3l-2-9.1h-.1c-1-12.4-11.4-22-24.1-21.9-13.2.2-23.6 11.3-23.6 24.5v6.6h-17.9c-4.8-264.2-109.1-443.6-109.1-443.6l-8.7 3.1c-7.9-17.7-44.4-76.4-44.4-76.4l-46.9 29.5c20.2 31.4 57.1 101.1 57.1 101.1l-12 6c15.3 34.2 27.9 67.9 38.3 100.5l7.4-2.3c-3.7-11.3-8.9-26.4-14.1-39.6l57.5-21s13.7 36.1 20.8 60l-58.7 17.4s-1-3.3-2.7-8.6l-7.4 2.6c12.3 39.7 21.3 77.7 27.9 112.7l7.1-.9c-4.4-23.8-9.6-49.6-15.6-72.5l59.8-16.3s25.6 103.9 29.5 164.9l-60.6 6.4s-4.3-33-11.5-73.8l-7 1.3c11 61.2 14.7 112.9 15.6 148.8l6.7-.1-1.2-41.2 60.9-2.8c.8 12.2 1.7 64.7 1.7 64.7l-60.8.8-.4-12.8-6.8.3c.4 28.5-.8 44.9-.8 44.9l18.7 1-3.1 36.5 49.6 3.7 4.4-37.7h72v42.1H1176v47.9h53.9v61.9h-11.4l-.2 90.1v6h-88.8v-55.5h-46c-110.4-25.1-186.8-26.3-186.8-26.3v-1.1h-21v2.3l18.1 1.8v2.3l4 .1s83.4-.1 177.6 27l-1 3.2 1.5.6 2.2-5.9 4.5 1.5.8-2.5 37.4-.8v78.6h-4.8v-4.6l-37.6 29.4-13.2-16.7s-29.2 24.1-70.7 24.6c-18.9.2-37.6-5.6-52.4-17.4-45.3-36.1-13.3-91.4-13.3-91.4l-34.6-6.3-9.6-1.4s-18 26.6-4.5 62.3h-63.5v-84.5h18.1v-2.3h-18.1l-35.8 5.5v114.8h24.3V911h11.6v35.7l-328.7-.3V793.3l-9.9-14.3v-49H533v-47.4h-55.4v-42.5h73.2l4.8 38.4 50-3.9-2.1-37.8 17.6.2s-3.7-40.8 1.9-107.5l-6.9-.6c-1 16-2.2 41.7-2.2 72.8l-61 1.7s-.3-58.9 3.7-97.7l60.8 5.4s-.3 3.2-.7 8.9l7.1.8c3.1-33.4 8.6-72.6 17.8-116.1l-8.2-1.8c-4.6 21.5-9 46.5-12.5 73.7l-60.9-7.8s14.4-101.5 35.4-163.6l59.1 17.2s-9.5 27.7-19.2 71.8l8.2 1.6c9.7-43.8 23.3-91.7 42.2-141.9l-6.6-2.7-13.8 36-57.9-18.7 22.8-59.6 56 23.7-4 10.5 6.7 2.3c5.3-13.8 11.1-27.8 17.2-41.9l-13.2-5.9s21.8-43.4 57.1-99.1c.4-.6-.4.6 0 0l-46-31.2s-37 58.5-48.3 76.3l-5.7-3.8S541.8 328.1 541.5 583.9h-18.6v-6.3c0-13.2-10.4-24.3-23.6-24.5-12.7-.2-23.1 9.5-24.1 21.9h-.1l-5.5 25.9h.1l-29.6 138 8.4 1.9-11.1 52.7L403.6 950h416.9v-50.2h62.6c2.9 6 6.7 11.6 10.9 16.7 19.2 23.6 47.6 37.7 77.9 39.9 56.4 4.2 92.1-13.3 92.1-13.3l8.2 7.9h232.5l-49.1-231.7z"
        style={{
          fill: "#ededed",
        }}
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        d="m783.7 108.6-1.5 2.3L761 97.2s-20.7 32.1-46.6 81.3c0 0 60.8 41.1 141.5 41.1 67.3 0 118-28.3 118-28.3L941.5 135l21.3-13s-10.7-17.1-14.3-22.7c-3.9-6.1-15.9-24.1-15.9-24.1l-20.5 14.4-5.6-8.2 29.3-19.9 18 28.3 47.9-30.7 5.8 9.1 36.3-23.9L1015.2 0l-33 21.2 2.3 3.4-79.7 53.7L888 55.7l-68.3.3L804 77.6l-80.1-52.8 1.9-3.1-33-21.2-28.3 43.8 36.8 24.1 6-9.3 76.4 49.5z"
        style={{
          fill: "#ededed",
        }}
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        d="M980.7 205.4s-57.3 32-125.7 32c-59.3 0-110.5-24.5-123-30.9-1.6-.8-3.6-.1-4.3 1.5-106 235.3-84.5 405.3-81.7 424.1.2 1.1.9 2 1.9 2.4 4.6 1.9 19 7.6 40.4 14.1 1.6.5 2.5 2.2 2.1 3.8-3.2 11.9-13.8 60-3 133.6.3 2 2.4 3.2 4.2 2.5 2.2-.8 5.3-1.9 9.7-3.2 1.5-.4 2.5-1.9 2.3-3.5-.3-2-.7-4.7-.8-6.1-.1-.6 0-1.2.3-1.8l.9-1.8c.9-1.8-.2-4-2.1-4.5-2.5-.6-4.6-1.2-6.3-1.7-5.6-1.8-7.7-6.1-9.8-12.8 0 0-7.2-48.4 7.3-70.1 3.1-4.6 10.3-8.9 15.9-9.6 8-1 19.7-2 34.4-1.8 20.5.3 63.3.8 66.7 21.7 0 0 5 34.2-.3 54.7-.3 1-.4 2.1-.6 3.1-1.2 5.5-5.3 10-10.7 11.6-6 1.8-15.8 4.1-31.3 6.2-23.8 3.2-39.3 1.4-45.6.3-1.8-.3-3.5.9-3.7 2.7v1.1l.9 4.8c.3 1.7 2 2.9 3.7 2.5 22.5-4.5 57.3-9.1 108.6-10.3 1.7 0 3.1-1.4 3.1-3.2v-7.5h-6.6c-.6 0-1.2-.2-1.7-.5l-2.4-1.6c-.9-.6-1.4-1.6-1.4-2.7l.4-54.9c0-.8.3-1.6.9-2.2l1.2-1.2c.6-.6 1.4-.9 2.2-.9h56.4c1 0 1.9.4 2.5 1.2l1.8 2.3c.4.6.7 1.3.7 2V755c0 .8-.3 1.6-.9 2.2l-1.6 1.6c-.6.6-1.4.9-2.2.9h-10.8v7c0 1.7 1.4 3.1 3.1 3.2 98.7 1.7 125.2 15.5 129 17.9.4.2.8.4 1.2.4l20.3 2.8h.8c2.1-.3 11.1-1.6 11.6-4.5.5-3.3 24.8-149.3 25.4-152.8v-.1c3.8-35.4 4.2-70.4 2.4-104.3-10.3-178.4-85.8-323.9-85.8-323.9"
        style={{
          fill: "#ededed",
        }}
      />
      <MapRoom showRoomInfo={props.showRoomInfo} highlightedRoom={props.highlightedRoom} 
        id="venue_level3_map_svg__svg-session-room-1093"
        d="M980.9 667.7c5.6 25.6 22.8 31.5 27.6 32.7.8.2 1.3.9 1.2 1.7l-3.7 57.6-.5 12.1c0 .3-.3.6-.6.5l-12.5-.9c-.3 0-.5-.3-.5-.6v-2.2c0-.3-.2-.5-.5-.6-5.5-.4-53.5-4-65.4-3.7-5.4.2-9.3-2.1-11.9-4.8-3.1-3.1-4.9-7.4-5.3-11.8-.8-9-2.6-31.6-4.1-49-1.9-22.2 13.7-24.7 13.7-24.7l60.3-9.6c.9-.1 1.7.4 1.9 1.3 0 .8.2 1.5.3 2z"
        style={{
          fill: "#f6bbb0",
        }}
      />
    </G>
    <G
      id="venue_level3_map_svg__booths"
      style={{
        opacity: 0.63,
      }}
    >
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41xxx"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M818.8 834.3v-20h-3v20h3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41xxx1"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m816.7 891.8.3-3.6.9-3.6 1.1-3.5 1.9-3.1 2.1-3.1 2.6-2.6 3.1-2.1 3.1-1.9 3.5-1.3 3.6-.7 3.7-.3 3.6.3 3.6.7 3.5 1.3 3.2 1.9 3 2.1 2.6 2.6 2.1 3.1 1.9 3.1 1.3 3.5.7 3.6.3 3.6h-51.7"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41xxx2"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1129.3 861.2H1217.9v27.4h-88.6v-27.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41xxx3"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M644.2 517.3h9.3v24.4h-9.3z"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41626"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M723.1 929.9h17.1v17h-17.1v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41625"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M706.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41624"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M689.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41623"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M672.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41621"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M615.1 929.9h25.5v17h-25.5v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41620"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M598.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41619"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M581.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41618"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M564.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41616"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M530.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41617"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M547.1 929.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41615"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.5 915.9h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41614"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1217.9 838.4v22.8h-11.4v-22.8h11.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41613"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1194 849.7v-14.1h-31.2v14.1h31.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41612"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1151.5 854.4v-22.2h-22.2v22.2h22.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41611"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
        }}
        d="M815.8 843h5.9v29.9h-5.9z"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41610"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M751.9 850.1h28.4v28.4h-28.4v-28.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41609"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M751.9 891.3h28.4v28.4h-28.4v-28.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41608"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M738.5 889.7v-39.6h-34v39.6h34"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41607"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M738.5 918.7v-15.9h-34v15.9h34"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41606"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M691.7 918.7v-15.9h-34v15.9h34"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41605"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M691.7 889.7v-34h-34v34h34"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41604"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M644.9 891.3v-39.6h-11.3v39.6h11.3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41603"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M608 856h17.1v17H608v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41602"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M608 873h17.1v17H608v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41601"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M644.9 918.7v-15.9H608v15.9h36.9"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41600"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M593.9 890v-39.8H557V890h36.9"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41594"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.5 881.8h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41599"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M593.9 918.7v-15.9H557v15.9h36.9"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41597"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M541.6 918.7v-38.4H520v38.4h21.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41598"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M541.6 867.2v-34H520v34h21.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41596"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.5 898.8h17v17.1h-17v-17.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41595"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.6 845.1h17v22.7h-17v-22.7"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41593"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1217.9 815.8v22.8h-11.4v-22.8h11.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41592"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1217.9 793.1v22.7h-11.4v-22.7h11.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41591"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1194 835.6v-14.3h-31.2v14.3h31.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41590"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1194 809.9v-17h-31.2v17h31.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41589"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1086.5 820.9h65v11.3h-67.7l2.7-11.3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41588"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1151.5 792.9v17h-62.4v-17h62.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41587"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1002.7 810.1v-1.2l-.5-1-.4-.9-.7-.8-.7-.8-1-.5-1-.3-1.1-.2h-1.1l-1 .3-1 .5-.9.5-.7.9-.6.9-.4 1-.2 1v1.1l.2 1 .4 1.1.6.8.7.9.9.6 1 .4 1 .3h1.1l1.1-.2 1-.3 1-.5.7-.8.7-.8.4-1 .5-1v-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41586"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M991.4 808.5v-1l-.4-1.2-.4-.9-.7-.8-.8-.7-1-.6-1-.3-1-.1H985l-1.1.2-1 .5-.8.6-.7.8-.6.9-.5 1-.1 1v1.1l.1 1.1.5 1 .6.8.7.9.8.6 1 .4 1.1.3h1.1l1-.2 1-.2 1-.6.8-.7.7-.9.4-.9.4-1.1v-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41585"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m991.4 785.7-.1-1.1-.3-1-.4-1.1-.7-.8-.8-.6-1-.6-1-.4-1-.1-1.1.1-1.1.1-1 .5-.8.7-.7.7-.6 1-.5.9-.1 1.1v1l.1 1.2.5 1 .6.9.7.7.8.7 1 .4 1.1.3h1.1l1-.1 1-.4 1-.5.8-.7.7-.9.4-1 .3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41584"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m980.3 807-.1-1-.3-1.1-.6-.9-.5-.9-.9-.7-.9-.6-1-.2-1.1-.2h-1l-1.2.3-.9.4-.8.6-.9.9-.6.8-.2 1-.3 1.1v1.1l.3 1 .2 1 .6.9.9.9.8.5.9.5 1.2.3h1l1.1-.2 1-.3.9-.6.9-.7.5-.8.6-.9.3-1.2.1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41583"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M979.6 784v-1l-.3-1-.5-1-.6-.9-.9-.7-1-.6-1-.3-1-.1h-1l-1.2.3-1 .4-.8.7-.8.7-.5.9-.5 1-.1 1.2v1l.1 1 .5 1 .5 1 .8.7.8.6 1 .4 1.2.3 1 .1 1-.1 1-.4 1-.6.9-.7.6-.7.5-1.1.3-1V784"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41582"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M969 805.7v-1.1l-.3-1-.6-.9-.6-.9-.8-.7-1-.5-1.1-.3-1-.2h-1.1l-1 .3-1 .4-.9.6-.7.9-.6.8-.4 1.1-.2 1v1.1l.2 1 .4 1 .6.9.7.9.9.5 1 .5 1 .3h1.1l1-.2 1.1-.3 1-.6.8-.7.6-.8.6-.9.3-1.2v-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41581"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m968.1 782.5-.1-1-.3-1.1-.6-.9-.6-.8-.8-.8-.9-.5-1-.3-1.2-.2h-1l-1.1.3-.9.4-1 .6-.7.9-.6.9-.4 1-.2 1v1.1l.2 1 .4 1 .6.9.7.9 1 .5.9.5 1.1.3h1l1.2-.2 1-.3.9-.5.8-.8.6-.8.6-.9.3-1 .1-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41580"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m957.7 804.6-.1-1.2-.3-1-.4-1-.8-.7-.8-.7-.9-.6-1-.4-1.1-.2-1.1.2-1 .1-1 .6-.8.6-.8.7-.7 1-.3 1-.2 1v1l.2 1.2.3 1 .7.8.8.8.8.7 1 .4 1 .3h1.1l1.1-.1 1-.3.9-.6.8-.7.8-.9.4-1 .3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41579"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m956.6 781.2-.2-1-.3-1-.4-1-.7-.8-.9-.8-.8-.4-1-.4-1.1-.2h-1.1l-1 .3-1 .5-.9.7-.7.7-.6.9-.4 1-.3 1.1v1l.3 1.2.4.8.6 1.1.7.7.9.7 1 .4 1 .2 1.1.1 1.1-.1 1-.5.8-.4.9-.7.7-.9.4-1 .3-1 .2-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41578"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m946.5 803.4-.1-1-.3-1-.5-1-.7-.9-.9-.7-.8-.4-1-.4-1.2-.2h-1l-1 .3-1 .4-.9.8-.7.7-.6.8-.4 1-.3 1.2v1l.3 1 .4 1 .6 1 .7.8.9.5 1 .6 1 .1 1 .2 1.2-.2 1-.4.8-.6.9-.7.7-.7.5-1 .3-1 .1-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41577"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m945.1 780-.2-1.1-.3-1.1-.6-.9-.5-.8-.9-.8-.9-.5-1-.3-1.1-.2h-1l-1.2.3-.8.5-.9.5-.9.9-.5.9-.3 1-.3 1v1.1l.3 1 .3 1 .5.9.9.9.9.6.8.4 1.2.3h1l1.1-.2 1-.3.9-.5.9-.8.5-.8.6-.9.3-1.1.2-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41576"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m935.3 802.6-.2-1.2-.3-1-.4-.9-.7-.8-.7-.7-1.1-.6-1-.3-1-.2h-1.1l-1 .3-1 .5-.9.5-.7.9-.6.9-.4 1-.2 1v1.1l.2 1.1.4 1 .6.8.7.9.9.6 1 .4 1 .3h1.1l1-.2 1-.2 1.1-.6.7-.7.7-.9.4-.9.3-1.1.2-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41575"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m933.7 778.5-.2-1.1-.3-1-.5-.9-.6-.9-.9-.7-.8-.6-1-.3-1.2-.1h-1l-1.2.3-.8.4-.9.6-.8.9-.6.8-.3 1-.3 1v1.2l.3 1 .3 1 .6.9.8.8.9.6.8.4 1.2.3h1l1.2-.1 1-.3.8-.6.9-.7.6-.9.5-.8.3-1.2.2-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41574"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m924 801.7-.1-1-.3-1-.6-1-.6-.9-.8-.7-.9-.4-1.1-.5-1-.1h-1.1l-1.1.3-.9.4-1 .7-.7.7-.6.9-.4 1-.2 1.2v1l.2 1.1.4.9.6 1 .7.7 1 .7.9.5 1.1.1 1.1.2 1-.2 1.1-.4.9-.6.8-.6.6-.8.6-1 .3-1 .1-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41573"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m922 777.9-.1-1.1-.3-1-.6-.9-.6-.9-.8-.7-.9-.5-1.2-.3-1-.2h-1l-1.1.3-1 .4-.9.6-.7.9-.6.8-.4 1.1-.2 1v1.1l.2 1 .4 1 .6.9.7.9.9.5 1 .5 1.1.3h1l1-.2 1.2-.3.9-.6.8-.7.6-.8.6-.9.3-1.2.1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41572"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m912.6 801.1-.1-1.1-.3-1-.4-1-.7-.8-.9-.7-.9-.6-1-.2-1.1-.3-1 .1-1 .3-1.1.4-.8.6-.9.7-.6 1-.2 1-.3 1.1v1.1l.3 1 .2 1 .6.9.9.8.8.6 1.1.5 1 .2h1l1.1-.1 1-.3.9-.6.9-.7.7-.8.4-1.1.3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41571"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m898.1 777.4-.1-1.2-.3-1-.6-1-.6-.7-.8-.7-.9-.6-1.2-.5-1-.1-1 .1-1.1.3-1 .5-.9.5-.7.8-.6 1-.4 1-.2 1v1.1l.2 1 .4 1.1.6.8.7.9.9.6 1 .4 1.1.3h1l1-.2 1.2-.3.9-.5.8-.8.6-.8.6-1 .3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41570"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m901.4 800.6-.1-1.2-.3-1-.5-1-.7-.9-.8-.6-.9-.5-1-.5-1.2-.1-1 .1-1 .2-1 .4-.9.7-.7.7-.5 1.1-.5 1-.3 1v1l.3 1.1.5 1 .5.9.7.7.9.7 1 .5 1 .3h1l1.2-.2 1-.4.9-.4.8-.8.7-.8.5-1 .3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41569"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M886.4 777.4v-1l-.4-1.2-.4-.9-.8-.8-.7-.7-1-.6-1-.3-1-.2H880l-1.1.3-1 .5-.8.5-.8.9-.5.9-.5 1-.1 1v1.1l.1 1 .5 1.1.5.8.8.9.8.6 1 .4 1.1.3h1.1l1-.2 1-.3 1-.5.7-.8.8-.8.4-.9.4-1.1v-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41568"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m834.9 776.2-.2-1-.3-1-.5-1-.6-.9-.9-.7-.8-.6-1.2-.3-1-.1h-1l-1.2.3-.8.4-1 .6-.8.9-.5.8-.5 1-.1 1.2v1l.1 1 .5 1 .5 1 .8.7 1 .6.8.4 1.2.3 1 .2 1-.2 1.2-.4.8-.6.9-.7.6-.7.5-1 .3-1 .2-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41567"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m823.3 776.4-.1-1.1-.3-1-.6-1-.5-.8-.9-.8-.9-.4-1-.4-1.1-.2h-1l-1.2.3-.9.5-.8.7-.9.7-.6.9-.2 1-.3 1.1v1l.3 1.2.2.8.6 1.1.9.7.8.7.9.4 1.2.2 1 .1 1.1-.1 1-.5.9-.4.9-.7.5-.9.6-1 .3-1 .1-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41566"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m827.7 799.8-.2-1.1-.3-1-.4-.9-.7-.9-.7-.7-1-.6-1.1-.2-1-.3-1.1.1-1 .3-1 .4-.9.6-.7.7-.6 1-.4 1-.2 1.1v1.1l.2 1 .4 1 .6.9.7.9.9.5 1 .5 1 .2h1.1l1-.1 1.1-.3 1-.6.7-.7.7-.8.4-1.1.3-1 .2-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41565"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m800 778.2-.1-1-.3-1-.5-1-.7-.9-.8-.7-.9-.4-1-.4-1.2-.2h-1l-1 .3-1 .4-.9.7-.8.8-.6.8-.3 1-.3 1.2v1l.3 1 .3 1 .6 1 .8.7.9.6 1 .6 1 .1 1 .2 1.2-.2 1-.4.9-.6.8-.5.7-.9.5-1 .3-1 .1-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41564"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M804.2 804.6v-1l-.4-1-.5-1-.7-.9-.7-.7-1-.6-1-.3-1-.1h-1.2l-1 .3-1 .4-.9.6-.7.8-.6.9-.4 1-.1 1v1.2l.1 1 .4 1 .6 1 .7.7.9.6 1 .4 1 .3 1.2.1 1-.2 1-.3 1-.6.7-.7.7-.7.5-1.1.4-1v-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41563"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m788.5 778.9-.2-1.1-.2-1-.5-.9-.7-.8-.9-.8-.8-.5-1-.3-1.2-.3-1 .1-1 .3-1 .4-.9.6-.7.7-.6 1.1-.4 1-.3 1v1.1l.3 1 .4 1 .6.9.7.9.9.5 1 .5 1 .3h1l1.2-.2 1-.3.8-.5.9-.8.7-.8.5-1 .2-1 .2-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41562"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m792.5 805.4-.1-1.1-.3-1-.4-1-.8-.9-.8-.7-.9-.4-1-.5-1.2-.1-1 .1-1 .2-1 .4-.8.7-.8.7-.5 1.1-.5.8-.3 1.2v1l.3 1.1.5 1 .5.9.8.7.8.7 1 .5 1 .3h1l1.2-.2 1-.4.9-.4.8-.8.8-.8.4-1 .3-1 .1-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41561"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m776.8 779.5-.1-1-.3-1.1-.6-.9-.6-.9-.8-.7-.9-.6-1.1-.3-1.1-.1h-1l-1.1.3-.9.4-1 .6-.7.9-.6.8-.4 1-.2 1v1.2l.2 1 .4 1 .6.9.7.8 1 .6.9.5 1.1.2h1l1.1-.1 1.1-.3.9-.6.8-.7.6-.9.6-.8.3-1.2.1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41560"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M751.9 811.4h28.4v38.7h-28.4v-38.7"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41559"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M765.2 780.8v-1l-.3-1-.6-1-.6-.9-.9-.7-1-.4-1-.5-1-.1h-1.1l-1 .3-1 .4-.9.7-.7.8-.6.8-.4 1-.2 1.2v1l.2 1 .4 1 .6 1 .7.7.9.6 1 .6 1 .1 1.1.2 1-.2 1-.4 1-.6.9-.5.6-.9.6-1 .3-1v-1.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41558"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M753.6 782.3v-1.2l-.4-1-.4-1-.8-.7-.7-.8-1-.5-1-.5-1-.1-1.2.1-1 .3-1 .5-.8.5-.8.8-.5 1-.5 1-.1 1v1l.1 1.1.5 1.1.5.8.8.7.8.8 1 .4 1 .3h1.2l1-.2 1-.3 1-.5.7-.8.8-.8.4-1 .4-1v-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41557"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M738.5 850.1v-37.4h-34v37.4h34"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41556"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m742.1 783.6-.1-1.1-.3-1.1-.5-.9-.7-.8-.7-.8-1-.5-1-.3-1-.2h-1.2l-1 .3-1 .5-.9.5-.7.9-.6.9-.4 1-.1 1v1.1l.1 1 .4 1 .6.9.7.9.9.6 1 .4 1 .3h1.2l1-.2 1-.3 1-.5.7-.8.7-.8.5-.9.3-1 .1-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41555"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m730.6 784.9-.2-1.1-.2-1-.5-1-.7-.8-.9-.8-.8-.4-1-.4-1.2-.2-1 .2-1 .1-1 .5-.9.7-.7.7-.6.9-.4 1-.3 1.1v1l.3 1.2.4 1 .6.9.7.7.9.7 1 .4 1 .2 1 .1 1.2-.1 1-.5.8-.4.9-.7.7-.9.5-1 .2-1 .2-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41554"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M691.7 855.7v-38h-34v38h34"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41553"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M644.9 851.7v-22.8h-11.3v22.8h11.3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41552"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M608 839h17.1v17H608v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41551"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M608 817.7V839h17.1v-10.1h19.8v-11.2H608"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41550"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M557 816.4h36.9v33.8H557z"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41549"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m622 797.2-23.7 4.8-35.7-.9v-26.6l54-10.1z"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41548"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M541.6 821.9v-28.3H520v28.3h21.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41547"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M541.6 778.8v-19.9H520v19.9h21.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41546"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.6 811.1h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41545"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M487.7 763.5h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41544"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.6 794.1h17v17h-17v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41543"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1220.7 747v31.2h-11.3V747h11.3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41542"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1192.4 730h28.3v17h-28.3v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41541"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1196.7 778.8v-19.9h-28.4v19.9h28.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41540"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M1169.8 730h22.6v17h-22.6v-17"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41539"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1121.8 753.6 29.5 4.5V780h-33.5l4-26.4"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41538"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1123.5 742.4 3.5-22.4 24.3 3.6v23.1l-27.8-4.3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41537"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1106.4 780 4.2-28.1-33.6-5.1-4.3 28 33.7 5.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41536"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1112.3 740.6 3.4-22.3-17.3-2.7-3.5 22.4 17.4 2.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41535"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1094.9 738 3.5-22.4-16-2.5-3.8 22.3 16.3 2.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41534"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m627.3 755.5 16.9-2.9 5.6 33.5-16.9 2.9-5.6-33.5"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41533"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m621.7 721.9 16.8-2.9 5.7 33.6-16.9 2.9-5.6-33.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41532"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m560.8 726.2 48.8-8 5.6 33.5-54.4 9.1v-34.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41531"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M521.7 730.5h17v17.2h-17v-17.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41530"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M487.7 730.5h34v17.2h-34v-17.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41529"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M487.7 747.7h17v15.8h-17v-15.8"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41528"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1145.3 712.4 3.4-22.5-28.1-4.3-3.3 22.5 28 4.3"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41527"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m1117.3 708.1 3.3-22.5-33.5-5-3.5 22.3 33.7 5.2"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41524"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m685.5 678.1-.1-1.1-.3-1-.5-1.1-.7-.8-.7-.6-1-.6-1-.4-1-.1-1.2.1-1 .1-1 .6-.9.6-.7.7-.6 1-.4 1-.3 1v1.1l.3 1.1.4 1 .6.9.7.7.9.7 1 .4 1 .3h1.2l1-.1 1-.4 1-.5.7-.7.7-.9.5-1 .3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41523"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m687.7 665.6-.2-1-.3-1-.4-1-.7-.9-.7-.7-1.1-.5-1-.4-1-.1h-1.1l-1 .3-1 .4-.9.7-.7.7-.6.9-.4 1-.2 1.2v1l.2 1 .4 1 .6 1 .7.7.9.6 1 .6 1 .1 1.1.1 1-.1 1-.4 1.1-.6.7-.6.7-.8.4-1.1.3-1 .2-1.1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41522"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m674.2 652.7-.1-1.1-.3-1-.6-.9-.6-.9-.8-.7-.9-.6-1-.2-1.1-.2h-1l-1.2.3-.9.4-.8.6-.9.9-.6.8-.3 1-.2 1v1.2l.2 1 .3 1 .6.9.9.8.8.6.9.5 1.2.2h1l1.1-.1 1-.3.9-.6.8-.7.6-.8.6-1.1.3-1 .1-1"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41520"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m616.1 688.3 16.8-2.8 5.6 33.5-16.8 2.9-5.6-33.6"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41518"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m560.8 691.8 43.2-7.2 3.7 22.3-46.9 7.9v-23"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-89758"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M490.6 828.1h17v17h-17z"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41521"
        transform="rotate(-72.44 654.772 649.055)"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M649.8 639.4h9.9v19.4h-9.9z"
      />
      <MapBooth showBoothInfo={props.showBoothInfo} highlightedBooth={props.highlightedBooth}
        id="venue_level3_map_svg__svg-booth-41525"
        style={{
          fill: "#fff",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="M671.2 697.1H682v13.7h-10.8z"
      />
    </G>
    <G id="venue_level3_map_svg__booths_x5F_nbr">
      <Text
        transform="translate(724.37 935.65)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V324"}
      </Text>
      <Text
        transform="translate(1202.37 843.65)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U360"}
      </Text>
      <Text
        transform="translate(1006.64 815.21)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T354"}
      </Text>
      <Text
        transform="translate(562.53 697.09)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R300"}
      </Text>
      <Text
        transform="translate(642.36 639.69)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R301/R303"}
      </Text>
      <Text
        transform="translate(678.07 653.27)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R305"}
      </Text>
      <Text
        transform="translate(691.69 667.39)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R307"}
      </Text>
      <Text
        transform="translate(689.35 679.84)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R309"}
      </Text>
      <Text
        transform="translate(685.83 705.67)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R311"}
      </Text>
      <Text
        transform="translate(685.83 712.87)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"/R313"}
      </Text>
      <Text
        transform="translate(618.16 693.9)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R302"}
      </Text>
      <Text
        transform="translate(488.73 753.47)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S300"}
      </Text>
      <Text
        transform="translate(488.89 736.23)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S301"}
      </Text>
      <Text
        transform="translate(522.97 736.52)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S303"}
      </Text>
      <Text
        transform="translate(563.4 733.08)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S305"}
      </Text>
      <Text
        transform="translate(624.15 727.58)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S307"}
      </Text>
      <Text
        transform="translate(631.52 770.94)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S308"}
      </Text>
      <Text
        transform="translate(491.43 799.79)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T300"}
      </Text>
      <Text
        transform="translate(489.12 769.92)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T301"}
      </Text>
      <Text
        transform="translate(491.58 816.7)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T302"}
      </Text>
      <Text
        transform="translate(521.06 799.91)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T304"}
      </Text>
      <Text
        transform="translate(520.86 764.57)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T303"}
      </Text>
      <Text
        transform="translate(565.35 786.26)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T305"}
      </Text>
      <Text
        transform="translate(491.01 887.38)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U300"}
      </Text>
      <Text
        transform="translate(491.77 851.01)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U301"}
      </Text>
      <Text
        transform="translate(491.35 833.93)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U303"}
      </Text>
      <Text
        transform="translate(491.35 904.4)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U302"}
      </Text>
      <Text
        transform="translate(521.22 886.11)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U304"}
      </Text>
      <Text
        transform="translate(520.86 839.59)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U305"}
      </Text>
      <Text
        transform="translate(558.19 908.43)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U306"}
      </Text>
      <Text
        transform="translate(609.27 909.08)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U308"}
      </Text>
      <Text
        transform="translate(558.27 856.31)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U307"}
      </Text>
      <Text
        transform="translate(608.92 879.67)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U309"}
      </Text>
      <Text
        transform="translate(608.94 861.58)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U311"}
      </Text>
      <Text
        transform="translate(635.1 856.87)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U313"}
      </Text>
      <Text
        transform="translate(658.5 861.52)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U315"}
      </Text>
      <Text
        transform="translate(659.17 908.87)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U316"}
      </Text>
      <Text
        transform="translate(706.03 909.14)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U318"}
      </Text>
      <Text
        transform="translate(705.58 856.01)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U319"}
      </Text>
      <Text
        transform="translate(753.11 897.45)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U322"}
      </Text>
      <Text
        transform="translate(753.23 856.24)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U323"}
      </Text>
      <Text
        transform="translate(557.7 821.91)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T306"}
      </Text>
      <Text
        transform="translate(609.05 823.16)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T308"}
      </Text>
      <Text
        transform="translate(609.28 845.06)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T310"}
      </Text>
      <Text
        transform="translate(633.76 834.86)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T312"}
      </Text>
      <Text
        transform="translate(658.64 823.71)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T314"}
      </Text>
      <Text
        transform="translate(711.8 819.14)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T318"}
      </Text>
      <Text
        transform="translate(754.63 817.3)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T322"}
      </Text>
      <Text
        transform="translate(702.87 790.98)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T315"}
      </Text>
      <Text
        transform="translate(730.58 798.13)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T317"}
      </Text>
      <Text
        transform="translate(741.21 772.61)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T319"}
      </Text>
      <Text
        transform="translate(753.94 795.37)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T321"}
      </Text>
      <Text
        transform="translate(763.76 768.95)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T323"}
      </Text>
      <Text
        transform="translate(763.06 806.74)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T324"}
      </Text>
      <Text
        transform="translate(775.44 793.05)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T325"}
      </Text>
      <Text
        transform="translate(790.57 818.66)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T326"}
      </Text>
      <Text
        transform="translate(787.93 768.63)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T327"}
      </Text>
      <Text
        transform="translate(810.63 790.61)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T329"}
      </Text>
      <Text
        transform="translate(831.31 801.43)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T328"}
      </Text>
      <Text
        transform="translate(826.61 790.65)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T331"}
      </Text>
      <Text
        transform="translate(857.03 779.9)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T335"}
      </Text>
      <Text
        transform="translate(872.47 801.77)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T336"}
      </Text>
      <Text
        transform="translate(885.51 791.23)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T337"}
      </Text>
      <Text
        transform="translate(491.28 921.81)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V300"}
      </Text>
      <Text
        transform="translate(530.08 935.6)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V304"}
      </Text>
      <Text
        transform="translate(548.28 935.6)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V306"}
      </Text>
      <Text
        transform="translate(564.84 935.65)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V308"}
      </Text>
      <Text
        transform="translate(582.82 935.65)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V310"}
      </Text>
      <Text
        transform="translate(599.75 935.75)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V312"}
      </Text>
      <Text
        transform="translate(616.21 935.52)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V314"}
      </Text>
      <Text
        transform="translate(673.22 935.79)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V318"}
      </Text>
      <Text
        transform="translate(690.31 935.79)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V320"}
      </Text>
      <Text
        transform="translate(707.39 935.89)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"V322"}
      </Text>
      <Text
        transform="translate(1171.18 735.82)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S359"}
      </Text>
      <Text
        transform="translate(1193.79 735.86)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S361"}
      </Text>
      <Text
        transform="translate(1209.86 753.59)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S362"}
      </Text>
      <Text
        transform="translate(1169.42 764.78)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S360"}
      </Text>
      <Text
        transform="translate(1122.39 762.2)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S358"}
      </Text>
      <Text
        transform="translate(1077.36 754.69)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S356"}
      </Text>
      <Text
        transform="translate(1081.78 722.8)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S353"}
      </Text>
      <Text
        transform="translate(1099.03 723.96)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S355"}
      </Text>
      <Text
        transform="translate(1127.37 727.93)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"S357"}
      </Text>
      <Text
        transform="translate(1122.23 694.32)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R357"}
      </Text>
      <Text
        transform="translate(1088.91 688.2)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"R355"}
      </Text>
      <Text
        transform="translate(1203.04 797.95)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T361"}
      </Text>
      <Text
        transform="translate(1203.01 820.71)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T362"}
      </Text>
      <Text
        transform="translate(1164.09 799.13)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T359"}
      </Text>
      <Text
        transform="translate(1163.88 827.01)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T360"}
      </Text>
      <Text
        transform="translate(1087.46 826.75)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T358"}
      </Text>
      <Text
        transform="translate(1136.55 798.55)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T357"}
      </Text>
      <Text
        transform="translate(898.96 815.25)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T338"}
      </Text>
      <Text
        transform="translate(900.27 768.95)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T339"}
      </Text>
      <Text
        transform="translate(912.15 792.96)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T340"}
      </Text>
      <Text
        transform="translate(916.4 769.92)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T341"}
      </Text>
      <Text
        transform="translate(920.29 816.87)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T342"}
      </Text>
      <Text
        transform="translate(928.95 764.55)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T343"}
      </Text>
      <Text
        transform="translate(932.55 794.28)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T344"}
      </Text>
      <Text
        transform="translate(945.06 771.74)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T345"}
      </Text>
      <Text
        transform="translate(942.66 817.89)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T346"}
      </Text>
      <Text
        transform="translate(953.92 794.79)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T347"}
      </Text>
      <Text
        transform="translate(960.83 819.93)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T348"}
      </Text>
      <Text
        transform="translate(967.19 774.6)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T349"}
      </Text>
      <Text
        transform="translate(976.19 798.13)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T350"}
      </Text>
      <Text
        transform="translate(986.69 777.94)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T351"}
      </Text>
      <Text
        transform="translate(978.64 822.73)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"T352"}
      </Text>
      <Text
        transform="translate(806.74 869.05)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U326"}
      </Text>
      <Text
        transform="translate(1164.06 841.36)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U359"}
      </Text>
      <Text
        transform="translate(1130.63 838.23)"
        style={{
          fontSize: 6,
          fontFamily: "'Poppins-SemiBold'",
        }}
      >
        {"U357"}
      </Text>
      <Path
        style={{
          fill: "none",
          stroke: "#000",
          strokeMiterlimit: 10,
        }}
        d="m716.5 786.8 5.4-1.2M736.4 790.9l.2-5.5M685.1 665.6l5.4-.2M671.4 651.8l5.4-.2M656.6 647.1l1.6-5.2M679.1 703.9l5.4-.2M682.4 678.3l5.4-.2M759.4 789.3l.3-5.4M747.9 779.5l.2-5.5M770.6 776.6l.2-5.4M794.2 775.5l.2-5.5M798.5 812.4l.2-5.4M817.5 784.7l.3-5.5M892.3 785.7l.2-5.4M829.2 785.2v-5.5M830.4 799.7h-5.5M877.9 777.4h-5.4M914.7 775.4l-1-5.3M951.6 778.4l.2-5.5M961.4 789.6l.2-5.5M974.5 781.1l.3-5.5M985.8 816.8l.2-5.4M963.1 814.1l.2-5.5M950.8 812.9l.2-5.4M928.8 810.9l.3-5.4M906.8 809.5l.3-5.5M988.1 783.6l3.2-4.4M976.6 804.5l1-5.4M918.4 798.8l1-5.4M1000 811l5.4.7M927 776.2l-1-5.3M939.4 777.4l-3-11.1M941 800.9l-.3-5.5M892.8 799.8h-5.5M783.9 805.4h-5.5M782.8 787.5l.2-5.4"
      />
    </G>
    <G id="venue_level3_map_svg__texts">
      <Text
        transform="translate(717.43 717.5)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"Amphi"}
      </Text>
      <Text
        transform="translate(709.93 737.6)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"HAVANE"}
      </Text>
      <Text
        transform="translate(926.87 720.47)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"Studio"}
      </Text>
      <Text
        transform="translate(951.37 740.57)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"A"}
      </Text>
      <Text
        transform="translate(975.78 876.56)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"Amphi"}
      </Text>
      <Text
        transform="translate(957.48 896.66)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"BORDEAUX"}
      </Text>
      <Text
        transform="translate(836.22 723.14)"
        style={{
          fontSize: "9.51px",
          fill: "#989ea2",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"private"}
      </Text>
      <Text
        transform="translate(836.42 732.54)"
        style={{
          fontSize: "9.51px",
          fill: "#989ea2",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"lounge"}
      </Text>
      <Text
        transform="translate(819.09 889.71)"
        style={{
          fontSize: "7.63px",
          fill: "#989ea2",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"open stage"}
      </Text>
      <Text
        transform="rotate(-78.311 573.963 -146.799)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"room 352AB"}
      </Text>
      <Text
        transform="rotate(78.481 321.076 855.8)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"room 342AB"}
      </Text>
      <Text
        transform="matrix(.02077 .9998 -.9998 .02077 1135.69 545.92)"
        style={{
          fill: "#8c53aa",
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"room"}
      </Text>
      <Text
        transform="matrix(.02077 .9998 -.9998 .02077 1115.86 558.84)"
        style={{
          fill: "#8c53aa",
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"341"}
      </Text>
      <Text
        transform="matrix(.02077 .9998 -.9998 .02077 1104.83 548.17)"
        style={{
          fontSize: 9,
          fill: "#8c53aa",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"overflow"}
      </Text>
      <Text
        transform="rotate(-88.24 602.43 -5.206) scale(.99997)"
        style={{
          fontSize: "16.76px",
          fontFamily: "'Poppins-Medium'",
          fill: "#bf6d62",
        }}
      >
        {"room 351"}
      </Text>
      <Text
        transform="rotate(90 63.5 586.1)"
        style={{
          fontSize: "4.26px",
          fill: "#999",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"IMCAS"}
      </Text>
      <Text
        transform="rotate(90 63.8 581.3)"
        style={{
          fontSize: "4.26px",
          fill: "#999",
          fontFamily: "'Poppins-Medium'",
        }}
      >
        {"ANIMATION"}
      </Text>
      <G id="venue_level3_map_svg__indications">
        <Path
          d="m665.2 780.4-3.1-15.2c-.3-1.3-1.6-2.2-2.9-1.9s-2.2 1.6-1.9 2.9l3.1 15.2-5.6 1.2 12.6 20.4 3.5-23.7-5.7 1.1zM1061.8 695.4l-3 15.2c-.2 1.3.6 2.6 1.9 2.9s2.6-.6 2.9-1.9l3-15.2 5.6 1-3.8-23.7-12.3 20.5 5.7 1.2z"
          style={{
            fill: "#d1232a",
          }}
        />
        <Path
          d="M1082 275.9h-12.5c.2-1.2 1.2-2.2 2.4-2.4.3.3.8.5 1.3.5h2.5c1 0 1.9-.8 1.9-1.9s-.8-1.9-1.9-1.9h-2.5c-.9 0-1.6.6-1.8 1.4-2 .5-3.6 2.2-3.9 4.2h-1.8c-.3 0-.6.3-.6.6v.6c0 .3.3.6.6.6h16.2c.3 0 .6-.3.6-.6v-.6c.1-.2-.2-.5-.5-.5zm-1.3 12.5h-1.5l.9-9.4h-12.5l.9 9.4h-1.5c-.3 0-.6.3-.6.6v.6c0 .3.3.6.6.6h13.8c.3 0 .6-.3.6-.6v-.6c-.1-.3-.4-.6-.7-.6zM655.1 245.5h-12.5c.2-1.2 1.2-2.2 2.4-2.4.3.3.8.5 1.3.5h2.5c1 0 1.9-.8 1.9-1.9s-.8-1.9-1.9-1.9h-2.5c-.9 0-1.6.6-1.8 1.4-2 .5-3.6 2.2-3.9 4.2h-1.8c-.3 0-.6.3-.6.6v.6c0 .3.3.6.6.6H655c.3 0 .6-.3.6-.6v-.6c.2-.3-.1-.5-.5-.5zm-1.2 12.5h-1.5l.9-9.4h-12.5l.9 9.4h-1.5c-.3 0-.6.3-.6.6v.6c0 .3.3.6.6.6H654c.3 0 .6-.3.6-.6v-.6c-.1-.4-.4-.6-.7-.6z"
          style={{
            fill: "#4b68af",
          }}
        />
        <Path
          d="M1089.1 646c7.2 0 12.9-5.8 12.9-12.9s-5.8-12.9-12.9-12.9-12.9 5.8-12.9 12.9c-.1 7.1 5.7 12.9 12.9 12.9"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M1090.5 629.1h3.1c.7 0 1.3.3 1.7 1 .2.4.3.8.3 1.2 0 .1 0 .2.2.3.4.1.6.3.6.8v1.7c0 .4-.3.6-.6.7-.1 0-.2.1-.2.2 0 .5-.1 1-.5 1.5s-.9.7-1.6.7h-5.5c.1-.1.2-.1.2-.2.5-.4 1-.9 1.6-1.3.1-.1.2-.1.4-.1h3.4c.2 0 .4-.2.4-.4v-4c0-.3-.1-.4-.4-.4h-2.3c-.1 0-.2 0-.2-.1.1-.7-.2-1.2-.6-1.6M1088.9 629.1c-.3.2-.5.5-.8.7-.3.3-.6.5-.9.8-.1.1-.2.2-.4.2h-3.1c-.2 0-.2.1-.3.3v4c0 .4.1.5.5.5h1.7c.1 0 .2 0 .2.2-.1.6.1 1 .6 1.4-.1.1-.1 0-.2 0h-2.5c-1.2 0-2.1-.9-2.1-2.1v-4c0-1.2.9-2.1 2.1-2.1h4.9c.1 0 .2 0 .3.1"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M1087 633.5h-.6c-.3 0-.4-.1-.5-.3s.1-.4.2-.5c.9-.7 1.7-1.4 2.6-2.1.2-.2.5-.4.7-.6.2-.2.4-.2.6-.1.2.1.2.3.1.6-.3.7-.6 1.3-.9 2-.1.1 0 .2.1.2h1.3c.2 0 .4.1.5.3s0 .4-.2.5l-3.3 2.7c-.2.2-.5.2-.6.1-.2-.1-.2-.3-.1-.5.2-.4.3-.7.5-1.1.1-.3.3-.6.4-1 .1-.1 0-.2-.1-.2h-.7"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M610.4 646c7.2 0 12.9-5.8 12.9-12.9s-5.8-12.9-12.9-12.9-12.9 5.8-12.9 12.9 5.8 12.9 12.9 12.9"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M611.8 629.1h3.1c.7 0 1.3.3 1.7 1 .2.4.3.8.3 1.2 0 .1 0 .2.2.3.4.1.6.3.6.8v1.7c0 .4-.3.6-.6.7-.1 0-.2.1-.2.2 0 .5-.1 1-.5 1.5s-.9.7-1.6.7h-5.5c.1-.1.2-.1.2-.2.5-.4 1-.9 1.6-1.3.1-.1.2-.1.4-.1h3.4c.2 0 .4-.2.4-.4v-4c0-.3-.1-.4-.4-.4h-2.3c-.1 0-.2 0-.2-.1.1-.7-.1-1.2-.6-1.6M610.3 629.1c-.3.2-.5.5-.8.7-.3.3-.6.5-.9.8-.1.1-.2.2-.4.2h-3.1c-.2 0-.2.1-.3.3v4c0 .4.1.5.5.5h1.7c.1 0 .2 0 .2.2-.1.6.1 1 .6 1.4-.1.1-.1 0-.2 0h-2.5c-1.2 0-2.1-.9-2.1-2.1v-4c0-1.2.9-2.1 2.1-2.1h4.9c.1 0 .2 0 .3.1-.1 0 0 0 0 0"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M608.3 633.5h-.6c-.3 0-.4-.1-.5-.3s.1-.4.2-.5c.9-.7 1.7-1.4 2.6-2.1.2-.2.5-.4.7-.6.2-.2.4-.2.6-.1.2.1.2.3.1.6-.3.7-.6 1.3-.9 2-.1.1 0 .2.1.2h1.3c.2 0 .4.1.5.3s0 .4-.2.5l-3.3 2.7c-.2.2-.5.2-.6.1-.2-.1-.2-.3-.1-.5.2-.4.3-.7.5-1.1.1-.3.3-.6.4-1 .1-.1 0-.2-.1-.2h-.7"
          style={{
            fill: "#fff",
          }}
        />
        <Circle
          cx={1034}
          cy={475.6}
          r={12.9}
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M1036.4 473.6h2.7c.4 1.5.9 3.1 1.3 4.6h-1.3v2.6h-2.7v-2.5h-1.3c0-.1 1.2-4.5 1.3-4.7zM1028.5 477.6h-.8v-1.5c0-.5 0-1 .1-1.5 0-.3 0-.6.1-.9h3.9v1.1c0 .5 0 1 .1 1.6v1.3h-.8v3.3h-2.7v-2.2c.1-.4.1-.8.1-1.2zM1033.3 480.9v-10.6h1v10.6h-1zM1031.1 471.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.8.6-1.3 1.3-1.3.7-.1 1.3.5 1.3 1.3zM1039 471.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.8.6-1.3 1.3-1.3.8-.1 1.3.5 1.3 1.3z"
          style={{
            fill: "#fff",
          }}
        />
        <Circle
          cx={672}
          cy={475.6}
          r={12.9}
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M674.4 473.6h2.7c.4 1.5.9 3.1 1.3 4.6h-1.3v2.6h-2.7v-2.5h-1.3c0-.1 1.2-4.5 1.3-4.7zM666.5 477.6h-.8v-1.5c0-.5 0-1 .1-1.5 0-.3 0-.6.1-.9h3.9v1.1c0 .5 0 1 .1 1.6v1.3h-.8v3.3h-2.7v-2.2c.1-.4.1-.8.1-1.2zM671.3 480.9v-10.6h1v10.6h-1zM669.1 471.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.8.6-1.3 1.3-1.3.7-.1 1.3.5 1.3 1.3zM677 471.7c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.8.6-1.3 1.3-1.3.8-.1 1.3.5 1.3 1.3z"
          style={{
            fill: "#fff",
          }}
        />
        <Circle
          cx={1125.1}
          cy={658.4}
          r={12.9}
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M1127.5 656.4h2.7c.4 1.5.9 3.1 1.3 4.6h-1.3v2.6h-2.7v-2.5h-1.3c0-.1 1.3-4.5 1.3-4.7zM1119.6 660.4h-.8v-1.5c0-.5 0-1 .1-1.5 0-.3 0-.6.1-.9h3.9v1.1c0 .5 0 1 .1 1.6v1.3h-.8v3.3h-2.7v-2.2c.1-.4.1-.8.1-1.2zM1124.4 663.7v-10.6h1v10.6h-1zM1122.2 654.4c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.8.6-1.3 1.3-1.3.8 0 1.3.6 1.3 1.3zM1130.2 654.4c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3c0-.8.6-1.3 1.3-1.3.7 0 1.2.6 1.3 1.3z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M1079.2 648.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9c-.1-7.1 5.7-12.9 12.9-12.9zm3.2 18.2c0-.7-.5-1.2-1.2-1.2h-.8v-4.5c0-.7-.5-1.2-1.2-1.2h-1.6c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h.4v3.2h-.8c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h4c.7.1 1.2-.5 1.2-1.1zm-1.6-10.1c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6.7 1.6 1.6 1.6 1.6-.7 1.6-1.6z"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M1081.2 665.3c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2h-4c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h.8V662h-.4c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h1.6c.7 0 1.2.5 1.2 1.2v4.5h.8z"
          style={{
            fill: "#fff",
          }}
        />
        <Circle
          cx={1079.2}
          cy={656.4}
          r={1.6}
          style={{
            fill: "#fff",
          }}
        />
        <Defs>
          <Path
            id="venue_level3_map_svg__SVGID_00000089574223604280489370000006690651207437609359_"
            d="M1161 861.9h25.9v25.9H1161z"
          />
        </Defs>
        <ClipPath id="venue_level3_map_svg__SVGID_00000122697698539212873530000001234254033485681055_">
          <Use
            xlinkHref="#venue_level3_map_svg__SVGID_00000089574223604280489370000006690651207437609359_"
            style={{
              overflow: "visible",
            }}
          />
        </ClipPath>
        <G
          style={{
            clipPath:
              "url(#venue_level3_map_svg__SVGID_00000122697698539212873530000001234254033485681055_)",
          }}
        >
          <Path
            d="M1173.9 887.8c7.1 0 12.9-5.8 12.9-12.9s-5.8-12.9-12.9-12.9-12.9 5.8-12.9 12.9 5.8 12.9 12.9 12.9"
            style={{
              fill: "#666",
            }}
          />
        </G>
        <Path
          d="m1168.5 869.2 5.5 5.1c.7-.6.4-.4 1.1-1-.5-.8-.4-1.7.4-2.5.5-.5 2.2-1.7 2.3-1.8.4-.3.9.3.6.6l-1.9 2.2.1.1 2.2-1.8c.3-.3.9.2.6.6l-1.8 2.2.1.1 2.2-1.9c.3-.3.9.2.6.6-.1.1-1.3 1.8-1.8 2.3-.8.8-1.7.8-2.5.4-.6.7-.3.4-.9 1.1l3.6 3.3c.3.3.3.8 0 1.1l-.7.7c-.3.3-.8.3-1.1 0-.4-.5-4.6-5.4-4.8-5.7-3.7 0-5-2.1-5-5.2 0-.6.8-.9 1.2-.5zm.5 10.7.7.7c.3.3.8.3 1.1 0l2.7-3.1-1.3-1.5-3.2 2.8c-.3.3-.3.8 0 1.1z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M682 754.1c6.8 0 12.4-5.6 12.4-12.4s-5.6-12.4-12.4-12.4-12.4 5.6-12.4 12.4 5.6 12.4 12.4 12.4"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M677.4 738.2c-.6-.6-3.2-.8-4-.8-.1 0-.2 0-.2.1-.1.1-.2.2-.2.3v6.8c0 .2.2.4.4.4h2.3c.2 0 .3-.1.4-.2 0-.2 1.4-4.2 1.5-6.2-.1-.2-.1-.4-.2-.4zM685.4 740c-.8-.2-1.4-.5-2-.8-1.4-.6-2-.3-3.2.9-.5.5-.9 1.3-.8 1.6 0 .1.1.1.2.2.4.2.9.2 1.4-.6.1-.1.2-.2.3-.2.2 0 .3-.1.5-.2s.3-.2.5-.2h.2c.1 0 .2.1.3.1.4.4 1.1.9 1.7 1.5 1.1.8 2.1 1.7 2.6 2.4h.1c-.5-1.5-1.4-4-1.8-4.7zM690.7 738.1c-3 0-4.6.8-4.7.8l-.2.2c0 .1 0 .2.1.3.5.8 2 4.9 2.2 6 .1.2.2.3.4.3h2.3c.2 0 .4-.2.4-.4v-6.8c-.1-.3-.2-.4-.5-.4z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M686.7 745.5c-.3-.7-1.6-1.7-2.7-2.6-.6-.5-1.2-1-1.7-1.4-.1.1-.2.1-.2.2-.2.1-.3.2-.6.2-.6.8-1.4 1.1-2.2.8-.4-.1-.6-.4-.7-.7-.2-.8.5-1.8 1.1-2.3h-1.5c-.3 1.5-.8 3.5-1.2 4.5.3.3.6.7.8.8 1.4 1.2 3.1 2.4 3.4 2.6.2.2.7.3.9.3h.2l-1.2-1.2c-.2-.2-.2-.4 0-.5s.4-.2.5 0l1.5 1.5c.2.2.3.1.5.1.2-.1.3-.2.4-.5l-1.7-1.7c-.2-.2-.2-.4 0-.5.2-.2.4-.2.5 0l1.9 1.9c.1.1.4.1.6 0l.3-.3-2.1-2.1c-.2-.2-.2-.4 0-.5.2-.2.4-.2.5 0l2.2 2.2c.2.1.3 0 .5-.1 0-.2.1-.4 0-.7z"
          style={{
            fill: "#fff",
          }}
        />
        <Circle
          cx={774.1}
          cy={934.6}
          r={9.9}
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M781.2 939.9c0 .6-.6 1.1-1.3 1.1h-10.4c-.7 0-1.3-.5-1.3-1.1v-1.1c0-1.9 1.8-3.4 3.9-3.4h.3c.7.3 1.5.5 2.3.5s1.6-.2 2.3-.5h.3c2.2 0 3.9 1.5 3.9 3.4v1.1zm-9.8-10.5c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3zm3.1 18.2c7.1 0 12.9-5.8 12.9-12.9s-5.8-12.9-12.9-12.9-12.9 5.8-12.9 12.9 5.8 12.9 12.9 12.9M874.7 848.6c7.2 0 12.9-5.8 12.9-12.9s-5.8-12.9-12.9-12.9-12.9 5.8-12.9 12.9c-.1 7.1 5.7 12.9 12.9 12.9"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M874.1 831.7c-1.7-1.1-4.4-1.3-5.7-1.4-.5 0-.8.4-.8.8v8.4c0 .4.4.7.7.8 1.2.1 3.7.4 5.4 1.2.4.1.6 0 .6-.4V832s-.1-.2-.2-.3m6.9-1.3c-1.4.1-4 .4-5.7 1.4-.1.1-.2.2-.2.4v9.1c0 .4.4.5.6.4 1.7-.9 4.2-1.1 5.4-1.2.4 0 .7-.4.7-.8v-8.4c-.1-.5-.4-1-.8-.9"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M667.3 678.5h-11c-1.5 0-2.6-1.2-2.6-2.6v-11c0-1.5 1.2-2.6 2.6-2.6h11c1.5 0 2.6 1.2 2.6 2.6v11c0 1.4-1.2 2.6-2.6 2.6z"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M663 676.2c.5-.5.9-.9 1.2-1.4.1-.2.3-.3.4-.5 0 0 0-.1.1-.1.1-.4.2-.7.3-1.1v-.3c-.1-.1-.1-.2 0-.4.2-.4.3-.9.5-1.3 0 0 0-.1.1-.1v-.2h-.2c-.2.1-.5.2-.7.4-.1 0-.2.1-.2.2-.1.1-.1.3-.1.4-.2.8-.5 1.5-.7 2.3-.2.5-.3 1-.5 1.6-.1.1-.1.3-.2.5zM666.6 664.5c-.7 2.2-1.3 4.3-2 6.5v.1l1.5-.9 2-5.7h-1.5zM662.1 675.8c.1-.2.1-.3.1-.4.1-.2.1-.4.2-.6l.3-.9.3-.9c.1-.5.3-.9.4-1.4v-.1c0-.1.1-.2.1-.4l.1-.3v-.3l-.1-.1c-.3-.3-.7-.5-1.2-.6-.5-.2-1-.3-1.5-.5-.4-.1-.8-.2-1.1-.3s-.6-.2-.8-.3-.4-.3-.5-.4c-.1-.2-.2-.4-.2-.6 0-.4.2-.7.5-1 .3-.2.8-.3 1.4-.3.3 0 .7 0 1 .1l.9.3c.3.1.5.3.7.4.2.1.3.2.4.3l1-1.8c-.5-.3-1.1-.6-1.8-.9-.7-.3-1.4-.4-2.2-.4-.6 0-1.1.1-1.6.2-.5.2-1 .4-1.3.7-.4.3-.7.7-.9 1.1-.2.5-.3 1-.3 1.6 0 .4.1.8.2 1.1.1.3.3.6.6.8s.6.5 1 .6c.4.2.9.3 1.4.5.5.1.9.2 1.2.4.4.1.7.2.9.4.3.1.5.3.6.5s.2.4.2.6c0 .8-.6 1.2-1.8 1.2-.4 0-.9-.1-1.3-.2s-.8-.2-1.2-.4c-.3-.2-.6-.3-.9-.5-.3-.2-.4-.3-.5-.4l-1 1.9c.7.5 1.4.8 2.3 1.1.8.3 1.7.4 2.6.4.6 0 1.1-.1 1.6-.2h.2c-.1.1 0 .1 0 0z"
          style={{
            fill: "#fff",
          }}
        />
        <Path
          d="M817.7 838.2c7.1 0 12.9-5.8 12.9-12.9s-5.8-12.9-12.9-12.9-12.9 5.8-12.9 12.9 5.7 12.9 12.9 12.9"
          style={{
            fill: "#666",
          }}
        />
        <Path
          d="M824.1 819.3h-9c-.6 0-1.1.5-1.1 1.1v1.1c.5 0 1 .2 1.6.4v-1.1h8.3v6.8h-1.6V826h-3v1.6h-1.8c.4.4.8.9.9 1.6h5.8c.6 0 1.1-.5 1.1-1.1v-7.5c-.1-.8-.6-1.3-1.2-1.3m-10.2 7.5c1.2 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3m1.1.8h-.2c-.3.1-.6.2-.9.2s-.6-.1-.9-.2h-.2c-1.4 0-2.7 1.2-2.7 2.7 0 .6.5 1.1 1.1 1.1h5.3c.6 0 1.1-.5 1.1-1.1.1-1.6-1.1-2.7-2.6-2.7"
          style={{
            fill: "#fff",
          }}
        />
      </G>
    </G>
  </Svg>
);
const ForwardRef = forwardRef(SvgVenueLevel3Map);
export default ForwardRef;

