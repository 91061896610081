import React from 'react';
import {Flex, FormControl, Select, Text, useTheme, View, VStack} from "native-base";
import {Controller} from "react-hook-form";
import Select2Form from "./Select2Form";
import {MaterialIcons} from "@expo/vector-icons";


const SelectForm = ({
                        name,
                        control,
                        defaultValue,
                        placeholder,
                        width,
                        items,
                        required,
                        validation,
                        errors,
                        apiErrors,
                        label,
                        isSelect2,
                        valueShow = "label",
                        valueSend = "value",
                        isNotPlaceholderSendValue
                    }) => {
    const {colors} = useTheme();

    const randomKey = Math.random().toString(36).substring(7);
    let countKey = 0;

    return (
        <>
            <VStack mb={4} zIndex={isSelect2 ? 5 : 0}>
                <Controller
                    control={control}
                    rules={{
                        ...validation,
                        required: required,
                    }}
                    render={({field: {onChange, onBlur, value}}) => (
                        <FormControl isInvalid={errors[name] || apiErrors[name]}>
                            <FormControl.Label>{label}</FormControl.Label>

                            {
                                isSelect2 ?
                                    <Select
                                        _selectedItem={{
                                            bg: colors.white,
                                            paddingRight: 2,
                                            _icon: {
                                                color: '#fff'
                                            },
                                            _text: {
                                                overflow: "hidden",
                                            },
                                            startIcon: <MaterialIcons name={"radio-button-checked"} size={20}
                                                                      color={colors['action'][50]}></MaterialIcons>,
                                        }}
                                        _item={{
                                            bg: colors.white,
                                            paddingRight: 10,
                                            _text: {
                                                overflow: "hidden",
                                            },
                                            startIcon: <MaterialIcons name={"radio-button-unchecked"} size={20}
                                                                      color={colors}></MaterialIcons>
                                        }}
                                        _customDropdownIconProps={{
                                            size: 5
                                        }}
                                        _actionSheetContent={{
                                            bg: colors.white
                                        }}
                                        onBlur={onBlur}
                                        width={width}
                                        placeholder={placeholder}
                                        selectedValue={''+value}
                                        onValueChange={(itemValue) => onChange(itemValue)}
                                    >
                                        <Select.Item label="" value=""/>
                                        {items.map((item) => (
                                            <Select.Item key={randomKey + '-' + (countKey++) + '-' + item.value}
                                                         label={item.label}
                                                         value={item.value}/>))}
                                    </Select>
                                    :
                                    <Select
                                        onBlur={onBlur}
                                        width={width}
                                        placeholder={placeholder}
                                        selectedValue={''+value}
                                        onValueChange={(itemValue) => onChange(itemValue)}
                                    >
                                        <Select.Item label="" value=""/>
                                        {items.map((item) => (
                                            <Select.Item key={randomKey + '-' + (countKey++) + '-' + item.value} label={item.label} value={item.value}/>))}
                                    </Select>
                            }


                            {errors && errors[name] &&
                                <FormControl.ErrorMessage>This field is required </FormControl.ErrorMessage>}
                            {apiErrors && apiErrors[name] &&
                                <FormControl.ErrorMessage>{apiErrors[name]}</FormControl.ErrorMessage>}
                        </FormControl>
                    )}
                    name={name}
                    defaultValue={defaultValue}
                />
                {/*{errors && errors[name] && <Text>This field is required</Text>}*/}
                {/*{apiErrors && apiErrors[name] && <Text>{apiErrors[name]}</Text>}*/}
            </VStack>
        </>
    );
}
export default SelectForm;
